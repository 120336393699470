"use strict";
const devMode = process.env.NODE_ENV !== "production";

export function getHostUrl() {
  // if (!devMode) {
  //   return "https://api2.echojoy.co.in/ej/v2";
  // }

  // return "https://api2.echojoy.co.in/ej/v2";
  return "https://api2.eyantra.com/ej/v2";
  
}

export function getConfig() {
  if (!devMode) {
    return {
      RAZORPAY_KEY: "rzp_live_0wAIzpV5ZsHqib"
    };
  }

  return {
    RAZORPAY_KEY: "rzp_test_rQJMhOunswNbO3"
  };
}
