import {
  getPhoneNumbers,
  addPhoneNumberAPI,
  deletePhoneNumberAPI,
  createPhoneNumberAPI,
  getResellerOtp
} from "../apis/phoneNumbers";

export const REQUEST_PHONENUMBER_LIST = "REQUEST_PHONENUMBER_LIST";
export const REQUEST_PHONENUMBER_LIST_FAILED =
  "REQUEST_PHONENUMBER_LIST_FAILED";
export const REQUEST_PHONENUMBER_LIST_SUCCESS =
  "REQUEST_PHONENUMBER_LIST_SUCCESS";

export const DELETE_PHONE_NUMBER = "DELETE_PHONE_NUMBER";
export const DELETE_PHONE_NUMBER_SUCCESS = "DELETE_PHONE_NUMBER_SUCCESS";
export const DELETE_PHONE_NUMBER_FAIL = "DELETE_PHONE_NUMBER_FAIL";

export const ADD_PHONE_NUMBER = "ADD_PHONE_NUMBER";
export const ADD_PHONE_NUMBER_SUCCESS = "ADD_PHONE_NUMBER_SUCCESS";
export const ADD_PHONE_NUMBER_ERROR = "ADD_PHONE_NUMBER_ERROR";

export const REQUEST_RESELLER_OTP = "REQUEST_RESELLER_OTP";
export const REQUEST_RESELLER_OTP_SUCCESS = "REQUEST_RESELLER_OTP_SUCCESS";
export const REQUEST_RESELLER_OTP_FAIL = "REQUEST_RESELLER_OTP_FAIL";

import { ADD_FLASH_MESSAGE, DELETE_FLASH_MESSAGE } from "./flashMsgActions";

// request reseller otp
export const requestResellerOtp = data => async dispatch => {
  dispatch({ type: REQUEST_RESELLER_OTP });
  return getResellerOtp(data)
    .then(result => {
      dispatch({
        type: REQUEST_RESELLER_OTP_SUCCESS,
        new_phonenumber: data
      });
    })
    .catch(err => {
      dispatch({
        type: REQUEST_RESELLER_OTP_FAIL,
        err: "something went wrong"
      });
    });
};

// fetching phonenumbers
export const requestPhoneNumbers = data => async dispatch => {
  dispatch({ type: REQUEST_PHONENUMBER_LIST });
  return getPhoneNumbers(data)
    .then(result => {
      dispatch({
        type: REQUEST_PHONENUMBER_LIST_SUCCESS,
        phoneNumbers: result
      });
    })
    .catch(err => {
      dispatch({
        type: REQUEST_PHONENUMBER_LIST_FAILED,
        err: "something went wrong"
      });
    });
};

// delete phone number
export const deletePhoneNumber = data => async dispatch => {
  dispatch({
    type: DELETE_PHONE_NUMBER,
    phone: data.removeNumber
  });
  return deletePhoneNumberAPI(data)
    .then(res => {
      if (res.message == "SUCCESS") {
        dispatch({
          type: DELETE_PHONE_NUMBER_SUCCESS,
          phone: data.removeNumber
        });

        dispatch({
          type: ADD_FLASH_MESSAGE,
          variant: "success",
          flashMsg: "Successfully deleted reseller's phonenumber"
        });

        setTimeout(() => {
          dispatch({ type: DELETE_FLASH_MESSAGE });
        }, 3000);
      } else {
      }
    })
    .catch(e => {
      console.log(e);
    });
};

// add phone number
export const addPhoneNumber = data => async dispatch => {
  dispatch({
    type: ADD_PHONE_NUMBER
  });
  return addPhoneNumberAPI(data)
    .then(res => {
      if (res.message === "SUCCESS") {
        dispatch({
          type: ADD_PHONE_NUMBER_SUCCESS,
          phone: data.phone
        });

        dispatch({
          type: ADD_FLASH_MESSAGE,
          variant: "success",
          flashMsg: "Successfully added reseller's phonenumber"
        });

        setTimeout(() => {
          dispatch({ type: DELETE_FLASH_MESSAGE });
        }, 3000);
      } else if (res.message === "NUMBER_ALREADY_EXISTS") {
        dispatch({
          type: ADD_FLASH_MESSAGE,
          variant: "danger",
          flashMsg: "Number already exists"
        });
        setTimeout(() => {
          dispatch({ type: DELETE_FLASH_MESSAGE });
          dispatch({
            type: ADD_PHONE_NUMBER_ERROR
          });
        }, 3000);
      }
    })
    .catch(err => {
      dispatch({
        type: ADD_FLASH_MESSAGE,
        variant: "danger",
        flashMsg: "Enter valid OTP"
      });

      setTimeout(() => {
        dispatch({ type: DELETE_FLASH_MESSAGE });
      }, 3000);
    });
};

// add phone number
export const createPhoneNumber = data => async dispatch => {
  return createPhoneNumberAPI(data)
    .then(result => {
      return result;
    })
    .catch(err => {
      console.log(err);
    });
};
