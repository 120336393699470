import axios from "axios";
import { requestOtp, verifyOtp, resendOtp } from "../apis/users";

export const NOT_REQUESTED_OTP = "NOT_REQUESTED_OTP";
export const REQUEST_OTP = "REQUEST_OTP";
export const REQUEST_OTP_FAILED = "REQUEST_OTP_FAILED";
export const REQUEST_OTP_SUCCESS = "REQUEST_OTP_SUCCESS";
export const SENT_OTP = "SENT_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";
export const LOGOUT = "LOGOUT";
export const REQUEST_CREATE_SELLER = "REQUEST_CREATE_SELLER";
export const REQUEST_CREATE_SELLER_SUCCESS = "REQUEST_CREATE_SELLER_SUCCESS";
export const REQUEST_CREATE_SELLER_FAILED = "REQUEST_CREATE_SELLER_FAILED";
export const RESEND_OTP = "RESEND_OTP";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAIL = "RESEND_OTP_FAIL";

// example of a thunk using the redux-thunk middleware
export const logout = () => async dispatch => {
  dispatch({ type: "LOGOUT" }); // Cards can still be seen in the localhost
  return;
};

export const requestUserOtp = value => async dispatch => {
  const dispatchPhoneNumber = typeof value === 'object' ? value.phonenumber : value;
  if (dispatchPhoneNumber) {
    dispatch({ type: REQUEST_OTP, phoneNumber: dispatchPhoneNumber });
  }
  return requestOtp(value)
    .then(result => {
      if (result.data.code == "OTP_SENT") {
        dispatch({ type: REQUEST_OTP_SUCCESS });
      } else {
        dispatch({ type: REQUEST_OTP_FAILED });
      }
      return result;
    })
    .catch(e => {
      dispatch({ type: REQUEST_OTP_FAILED });

      setTimeout(() => {
        dispatch({ type: NOT_REQUESTED_OTP });
      }, 5000);

      return e;
    });
};

export const verifyUserOtp = (phonenumber, otp) => async dispatch => {
  dispatch({
    type: VERIFY_OTP_REQUEST,
    loading: true
  });
  return verifyOtp(phonenumber, otp)
    .then(result => {
      let {
        data: { code: replyCode }
      } = result;
      console.log(result)
      if (replyCode == "VERIFICATION_SUCCESS") {
        dispatch({ type: VERIFY_OTP_SUCCESS, result });
      } else {
        dispatch({ type: VERIFY_OTP_FAILED });
      }
    })
    .catch(e => {
      if (e.response.status === 403 || e.response.status === 500) {
        dispatch({ type: VERIFY_OTP_FAILED });
      } else {
        dispatch({ type: VERIFY_OTP_FAILED });
      }
      setTimeout(() => {
        dispatch({ type: NOT_REQUESTED_OTP });
      }, 5000);
    });
};

export const requestResendUserOtp = value => async dispatch => {
  dispatch({ type: RESEND_OTP, phoneNumber: value });
  return resendOtp(value)
    .then(result => {
      //let replyCode = JSON.parse(result.data.message);

      if (result.data.code == "OTP_SENT") {
        dispatch({ type: RESEND_OTP_SUCCESS });
      } else {
        dispatch({ type: RESEND_OTP_FAIL });
      }
    })
    .catch(() => {});
};
