import React from "react";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Button,
  Alert,
  Spinner,
  Card,
  Pagination,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import { Formik, Field, Form, ErrorMessage } from "formik";
import { EjNavbar } from "../EjNavbar";
import { getResellerActivity } from "../../actions/resellerActivityActions";
import "../../styles/prevPayments.scss";
import "../../styles/pagination.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

class PaymentsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      brandType: "AMAZON",
      validBrands: [
        {
          name: "Amazon",
          id: "1544",
          brandType: "AMAZON",
        },
        {
          name: "Flipkart",
          id: "9259",
          brandType: "FLIPKART",
        },
      ],
      activeBrandIndex: 0,
    };
  }
  //   UNSAFE_componentWillMount() {
  //     !_.get(this.props, "user.accessCode") && this.props.history.push("/login");
  //   }

  componentDidMount() {
    this.props.getResellerActivity({
      accessCode: this.props.user.accessCode,
      phone: this.props.user.phoneNumber,
      pageNumber: 1,
      brandType: "AMAZON",
    });
  }

  handleBrandSelect = async (event) => {
    await this.setState({ activeBrandIndex: event.target.value.toString() });

    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];

    // await this.props.debitHistorySwitchTab();

    await this.props.getResellerActivity({
      accessCode: this.props.user.accessCode,
      phone: this.props.user.phoneNumber,
      pageNumber: 1,
      brandType: activeBrand.brandType,
    });
  };

  getResellerActivityPage = (pageNumber) => {
    this.props.getResellerActivity({
      accessCode: this.props.user.accessCode,
      phone: this.props.user.phoneNumber,
      pageNumber: pageNumber,
      brandType: this.state.brandType,
    });
  };

  paginationItems = () => {
    const {
      page,
      size,
      totalCount,
      reseller_activity,
    } = this.props.resellerActivity;
    let totalPages = Math.ceil(totalCount / 100);
    let active = page;
    let items = [];

    for (let number = 1; number <= totalPages; number++) {
      if (reseller_activity.length !== 0) {
        items.push(
          <Pagination.Item
            key={number}
            active={number == active}
            onClick={() => this.getResellerActivityPage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }
    return <>{items}</>;
  };

  getActivity = () => {
    const { loading, reseller_activity } = this.props.resellerActivity;
    // console.log(reseller_activity);
    if (reseller_activity.length === 0) {
      return (
        <Alert variant="primary" style={{ textAlign: "center" }}>
          No Activity
        </Alert>
      );
    } else {
      return (
        <>
          {reseller_activity.map((activity, index) => {
            return (
              <>
                <Card className="resellerCard">
                  <Card.Body>
                    <Card.Text style={{ fontWeight: "600" }}>
                      <span style={{ fontSize: 18, marginRight: 20 }}>
                        {index + 1}.
                      </span>
                      {activity.brandType === "AMAZON" ? (
                        <img
                          src="https://static.echojoy.in/logos/amazon.png"
                          className="rounded"
                        />
                      ) : (
                        <img
                          src="https://static.echojoy.in/logos/flipkart.png"
                          className="rounded"
                        />
                      )}
                      <span>
                        {new Date(
                          parseInt(activity.activitytime)
                        ).toLocaleString()}
                      </span>
                      {activity.transactionType ? (
                        <span style={{ marginLeft: "10px" }}>
                          {activity.transactionType}
                        </span>
                      ) : (
                        <span style={{ marginLeft: "10px" }}>
                          {activity.eventType === "COMMISION_ON_CREDIT"}
                            <span>
                              CASHBACK
                            </span>
                        </span>
                      )}
                      {activity.parentCardBalance ? (
                        <span style={{ float: "right", padding: 15 }}>
                          Balance: &#8377;
                          {activity.parentCardBalance &&
                            parseFloat(activity.parentCardBalance).toFixed(2)}
                        </span>
                      ) : null}
                      <span
                        style={{ float: "right", padding: 15, color: "green" }}
                      >
                        <FontAwesomeIcon icon={faPlus} size="1x" /> &#8377;
                        {activity.value}
                      </span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </>
            );
          })}
          <Pagination>{this.paginationItems()}</Pagination>
        </>
      );
    }
  };

  render() {
    const { loading, reseller_activity } = this.props.resellerActivity;
    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];
    return (
      <>
        {/* <EjNavbar {...this.props} /> */}
        <Container>
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h2 className="element-header">Credit History</h2>

              <Form.Group>
                <Form.Label>Select Brand</Form.Label>
                <div className="inputSelect">
                  {this.state.validBrands.map((brand, index) => (
                    <Form.Check
                      // className={activeBrand.id === brand.id ? `active` : ""}
                      onClick={this.handleBrandSelect}
                      htmlFor={`${brand.name}`}
                      id={`${brand.name}`}
                      disabled={this.state.switchDisabled}
                      type={"radio"}
                      name="activeBrand"
                      label={`${brand.name}`}
                      value={`${index}`}
                      checked={activeBrand.id === brand.id}
                    />
                  ))}
                </div>
              </Form.Group>

              <br />
              {!loading ? (
                <>{this.getActivity()}</>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner animation="grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    resellerActivity: state.resellerActivity,
  };
};

export default connect(mapStateToProps, { getResellerActivity })(PaymentsPage);
