import React from "react";
import { connect } from "react-redux";
import { EjNavbar } from "../EjNavbar";
import {
  fetchPaymentId,
  makeSelfPayment,
  closeAlert,
  resetAddMoneyForm,
} from "../../actions/paymentActions";
//import logo from "../../static/images/red-white.png";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Spinner,
  Form,
} from "react-bootstrap";
// import { Formik, Field, Form, ErrorMessage } from "formik";
import { getCards } from "../../actions/cardActions";
import * as Yup from "yup";
import { getHostUrl, getConfig } from '../../utils/env';

const config = getConfig();

const LoadResellerMoneySchema = Yup.object().shape({
  amount: Yup.number().required("Required"),
});

class AddMoney extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      razorpayID: "",
      btnText: "Pay Now",
      validBrands: [
        {
          name: "Amazon",
          id: "1544",
          img: "https://static.echojoy.in/logos/amazon.png",
          custom_denominations: [10, 100, 200, 500, 1000, 2000, 5000, 10000],
          minValue: 10,
          maxValue: 10000,
        },
        {
          name: "Flipkart",
          id: "9259",
          img: "https://static.echojoy.in/logos/flipkart.png",
          custom_denominations: [50, 100, 200, 500, 1000, 2000, 5000, 10000],
          minValue: 10,
          maxValue: 10000,
        },
        {
          name: "JioMart",
          id: "279",
          img: "https://static.echojoy.in/logos/nyka.webp",
          custom_denominations: [100, 200, 500, 1000, 2000, 5000, 10000],
          minValue: 100,
          maxValue: 10000,
        },
        {
          name: "BigBasket",
          id: "733",
          img: "https://static.echojoy.in/logos/bigbasket.webp",
          custom_denominations: [500, 1000, 2000, 5000, 10000],
          minValue: 500,
          maxValue: 10000,
        },
      ],
      activeBrandIndex: 0,
      redeemValue: 0,
      disabled: false,
      alert: false,
      alertMessage: "",
    };
  }

  onSelectValue = (e, c) => {
    this.setState({
      redeemValue: e.target.getAttribute("data-value"),
      activeCustomDenominations: c,
    });
  };

  selfRazorPay = async () => {
    let paymentId;
    this.setState({ disabled: true });

    if (this.state.redeemValue <= 0) {
      this.setState({
        alert: true,
        alertMessage: `Please enter valid amount. Amount should be more than 0`,
        disabled: !this.state.disabled,
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          alertMessage: "",
          disabled: !this.state.disabled,
          loading: false,
        });
      }, 4000);

      return;
    } else {
      try {
        paymentId = await this.props.fetchPaymentId({
          accessCode: this.props.user.accessCode,
        });

        let activeCard = this.displayCard();
        
        let self = this;
        let razorpayTransactionId;
        let options = {
          key: config.RAZORPAY_KEY,
          amount: this.state.redeemValue * 100, // 2000 paise = INR 20, amount in paisa
          name: "Echojoy GiftCards",
          image: "https://static.echojoy.in/logos/red_white_logo.png",
          handler: async (response) => {
            await self.props.makeSelfPayment({
              paymentId: paymentId.paymentId,
              transactionId: response.razorpay_payment_id,
              cardId: activeCard.cardId,
              transactionType: "RESELLER_RAZOR_PAY",
              approvalStatus: "WAITING_FOR_APPROVAL",
              productId: activeCard.productId,
              razorpayPaymentAmount: parseInt(this.state.redeemValue),
              accessCode: self.props.user.accessCode,
              totalAmount: parseInt(this.state.redeemValue),
            });

            await this.setState({
              redeemValue: 0,
              activeBrandIndex: 0,
            });

            await this.props.getCards({
              access_token: this.props.user.accessCode,
              number: this.props.user.phoneNumber,
              pageNumber: 1,
            });
          },
          prefill: {
            contact: this.props.user.phoneNumber,
          },
          notes: {
            address: "Hello World",
          },
          theme: {
            color: "#fe4f38",
          },
        };

        let rzp = await new Razorpay(options);
        rzp.open();

        await this.setState({
          disabled: false,
          loading: false,
        });
        //await resetForm({});
      } catch (e) {
        console.log(e);
      }
    }
  };

  closeAlert = () => {
    this.props.closeAlert();
  };

  handleBrandSelect = (event) => {
    this.setState({ activeBrandIndex: event.target.value.toString() });
  };

  onInputChange = (e) => {
    this.setState({ redeemValue: e.target.value });
  };

 displayCard() {
    if (this.state.activeBrandIndex == 0) {
      return _.find(this.props.cards.cards, (c) => {
        return c.productId.toString() == "9142" && c.parent == true;
      });
    } else if (this.state.activeBrandIndex == 1) {
      return _.find(this.props.cards.cards, (c) => {
        return c.productId.toString() == "9146" && c.parent == true;
      });
    } else if (this.state.activeBrandIndex == 2) {
      return _.find(this.props.cards.cards, (c) => {
        return c.productId.toString() == "279" && c.parent == true;
      });
    } else if (this.state.activeBrandIndex == 3) {
      return _.find(this.props.cards.cards, (c) => {
        return c.productId.toString() == "733" && c.parent == true;
      });
    } else {
    }
  }

  render() {
    let displayCard = this.displayCard();
    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];

    if (this.props.cards.cards.length === 0) {
      return <p>There are no Cards. </p>;
    }

    return (
      <>
        <Container>
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <h2 className="element-header">Deposit Money Online</h2>
              {this.state.alert && (
                <Alert variant={"danger"}>{this.state.alertMessage} </Alert>
              )}

              {this.props.selfPayments.status === true &&
              this.props.selfPayments.variant === "danger" ? (
                <Alert variant="danger" onClose={this.closeAlert} dismissible>
                  Something went wrong. please try again.
                </Alert>
              ) : null}

              {this.props.selfPayments.status === true &&
              this.props.selfPayments.variant === "success" ? (
                <Alert variant="success" onClose={this.closeAlert} dismissible>
                  <Alert.Heading>Transaction successful</Alert.Heading>
                  <hr />
                 <p>
                    <b>Current Balance:{" "}
                    {displayCard.card_balance &&
                      parseFloat(displayCard.card_balance).toFixed(2)}</b>
                  </p>
                  <p>
                    <b>Transaction Amount: {this.props.selfPayments.totalAmount}</b>
                  </p>
                  <p><b>New Balance: Waiting for Approval</b></p>
                </Alert>
              ) : null}

              {!this.props.selfPayments.success &&
                !this.props.selfPayments.error && (
                  <>
                    <Form.Group>
                      <Form.Label>Select Brand</Form.Label>
                      <div className="inputSelect">
                        {this.state.validBrands.map((brand, index) => (
                          <Form.Check
                            // className={activeBrand.id === brand.id ? `active` : ""}
                            onClick={this.handleBrandSelect}
                            htmlFor={`${brand.name}`}
                            id={`${brand.name}`}
                            disabled={this.state.switchDisabled}
                            type={"radio"}
                            name="activeBrand"
                            label={`${brand.name}`}
                            value={`${index}`}
                            checked={activeBrand.id === brand.id}
                          />
                        ))}
                      </div>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Choose or Enter a Value to Redeem</Form.Label>
                      <Form.Control
                        type="text"
                        value={this.state.redeemValue}
                        placeholder=""
                        onChange={this.onInputChange}
                      />
                    </Form.Group>
                    <ul className="custom">
                      {activeBrand.custom_denominations.map((c) => {
                        return (
                          <li
                            data-value={c}
                            onClick={(e) => this.onSelectValue(e, c)}
                            className={
                              this.state.activeCustomDenominations === c &&
                              this.state.redeemValue !== 0
                                ? "active"
                                : ""
                            }
                            style={
                              this.state.activeCustomDenominations === c &&
                              this.state.redeemValue !== 0
                                ? {
                                    backgroundColor: "#007bff",
                                    color: "#ffffff",
                                  }
                                : {}
                            }
                          >
                            {c}
                          </li>
                        );
                      })}
                    </ul>
                    <Button
                      block
                      //   disabled={this.state.disabled}
                      disabled={
                        this.props.selfPayments.loading === true ||
                        this.state.loading
                          ? true
                          : false
                      }
                      variant="danger"
                      onClick={() => {
                        this.selfRazorPay();
                        this.setState({ loading: true });
                      }}
                    >
                      {this.state.loading === true ? (
                        <span style={{ marginRight: "10px" }}>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}

                      {this.state.btnText}
                    </Button>
                  </>
                )}

              {this.props.selfPayments.success &&
                !this.props.selfPayments.loading && (
                  <>
                    <Button
                      type="submit"
                      className="btn btn-primary btn-block"
                      onClick={() => {
                        this.props.resetAddMoneyForm();
                      }}
                    >
                      + Click to Add Balance Again.
                    </Button>
                  </>
                )}
              {this.props.selfPayments.error &&
                !this.props.selfPayments.loading && (
                  <>
                    <Alert variant={"danger"}>Unable to add Money!</Alert>
                    <Button
                      type="submit"
                      className="btn btn-primary btn-block"
                      onClick={() => {
                        this.props.resetAddMoneyForm();
                      }}
                    >
                      + Click to Retry Again
                    </Button>
                  </>
                )}

              {this.props.selfPayments.loading === true ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner animation="grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cards: state.cards,
    selfPayments: state.selfPayments,
  };
};

export default connect(mapStateToProps, {
  fetchPaymentId,
  makeSelfPayment,
  closeAlert,
  getCards,
  resetAddMoneyForm,
})(AddMoney);
