import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  Alert,
  Container,
  Row,
  Col,
  Form,
  Tabs,
  Tab,
  Spinner,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import { getCardsListReport } from "../../../actions/reportsActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { ButtonText, BaseReportTemplate } from "./BaseReportTemplate";
const moment = require('moment');

class CardsIssuedList extends BaseReportTemplate {

  state = {
    startDate: new Date(),
    endDate: new Date(),
    phone: "",
    loading: false,
    message: ButtonText.GENEREATE_REPORT,
    productId: "1544", // default productid for amazon
    email: ""
  };
  
  generateReport = async (e) => {
    this.setState({
      loading: true,
      message: ButtonText.GENERATING_REPORT,
    });

    e.preventDefault();

    const startDate = this.convertDateIntoString(this.state.startDate);
    const endDate = this.convertDateIntoString(this.state.endDate);
    const apiObj = {
      fromDate: startDate,
      toDate: endDate,
      productId: this.state.productId,
      phonenumber: this.props.user.phoneNumber,
      access_token: this.props.user.accessCode,
      email: this.state.email.split(",")
    };
    const data = await this.props.getCardsListReport(apiObj);
    this.setState({ message: ButtonText.DOWNLOAD_REPORT });
    const url = data.data.url;
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.setState({ loading: false, message: ButtonText.GENEREATE_REPORT });
  };

  render() {
    const { loading } = this.state;

    return (
      <>
        <Form>
          <Row>
            <Col md={{ span: 12, offset: 0 }}>
              {this.props.reports.error === true ? (
                <Alert variant={"danger"}>{this.state.alertMessage} </Alert>
              ) : null}
              <ButtonGroup
                info
                size="sm"
                style={{ marginBottom: 20, width: "100%" }}
              >
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    this.getTodayReport(e);
                  }}
                >
                  Today
                </Button>
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    this.getThisWeekReport(e);
                  }}
                >
                  This Week
                </Button>
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    this.getLastNDays(e, 3);
                  }}
                >
                  Last 3 days
                </Button>
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    this.getLastNDays(e, 7);
                  }}
                >
                  Last 7 days
                </Button>
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    this.getThisMonth(e);
                  }}
                >
                  This Month
                </Button>
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    this.getLastOneYear(e);
                  }}
                >
                  Last One Year
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Form.Row>
            <Col className="form-group">
              <label>Start Date</label>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleStartDateChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
                className="form-control"
              />
            </Col>
            <Col className="form-group">
              <label>End Date</label>
              <DatePicker
                selected={this.state.endDate}
                onChange={this.handleEndDateChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
                className="form-control asd"
              />
            </Col>
          </Form.Row>
          {/* <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              className="form-control"
              onChange={this.handlePhoneNumberChange}
            />
          </div> */}
          <Form.Row>
            <Col class="form-group">
              <label for="inputState">Select Brand</label>
              <select
                id="inputState"
                class="form-control"
                onChange={this.handleBrandChange}
              >
                <option value="1544">Amazon</option>
                <option value="9259">Flipkart</option>
                <option value="6861">Amazon SV</option>
              </select>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col class="form-group">
              <label for="inputState">Email</label>
              <input
              type="text"
              className="form-control"
              onChange={this.handleEmailChange}
            />
            </Col>
          </Form.Row>
          <br/>
          <Button
            type="submit"
            className="btn btn-primary btn-block"
            onClick={this.generateReport}
            disabled={this.state.loading}
          >
            {this.state.loading}
            {loading && (
              <Spinner
                animation="grow"
                role="status"
                size="sm"
                style={{ marginRight: 10 }}
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            {this.state.message}
          </Button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    reports: state.reports,
  };
};

export default connect(mapStateToProps, { getCardsListReport })(
  CardsIssuedList
);
