import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Button,
  Alert,
} from "react-bootstrap";
import _ from "lodash";

import * as actions from "../../actions/loginActions";
import RegisterForm from "../RegisterForm";
import { EjNavbar } from "../EjNavbar";
import { indianPhoneRegexValidation } from "../../utils/regx";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: null,
      firstname: "",
      lastname:'',
      email: "",
      error: false,
      errorMessage: null,
      otpRequested: false,
      otp :null,
    };
  }

  UNSAFE_componentWillMount() {
    window.localStorage.clear()
  }
  getOtp = async (e) => {
    e.preventDefault();
    if (
      this.state.phoneNumber &&
      this.state.firstname &&
      this.state.lastname &&
      this.state.email &&
      indianPhoneRegexValidation.test(this.state.phoneNumber)
    ) {
       const userDetails = {
        phonenumber: this.state.phoneNumber,
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
      }
      const data = await this.props.actions.requestUserOtp(userDetails);
        if (this.props.user.otpStatus === "REQUEST_OTP_FAILED") {
          this.setState({ otpRequested: false });
        } else {
          this.setState({ otpRequested: true });
        }
      } else {
        this.setState({
          otpRequested: false,
          error: true,
          errorMessage: "Please enter valid Details",
        });
        setTimeout(() => {
          this.setState({
            error: false,
          });
        }, 4000);
      }
    };

  verifyOtp = (event) => {
    event.preventDefault();
    if (!!this.state.phoneNumber && !!this.state.otp) {
      if (this.state.otp.length !== 6) {
        this.setState({ error: true, errorMessage: "OTP has to be 6 characters" });
        setTimeout(() => {
          this.setState({ error: false, errorMessage: "" });
        }, 4000);
        return;
      }

      var reg = new RegExp("^[0-9]+$");
      if (!reg.test(this.state.otp)) {
        this.setState({
          error: true,
          errorMessage: "OTP can only contain numbers",
        });
        setTimeout(() => {
          this.setState({ error: false, errorMessage: "" });
        }, 4000);
        return;
      }

      this.props.actions.verifyUserOtp(this.state.phoneNumber, this.state.otp);
    } else {
      this.setState({
        error: true,
        errorMessage: "Please enter both Phone Number and OTP",
      });
      setTimeout(() => {
        this.setState({ error: false, errorMessage: "" });
      }, 4000);
    }
  };

  resendOtp = (event) => {
    this.props.actions.requestResendUserOtp(this.state.phoneNumber);
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  resetPhoneNumber = () => {
    this.setState({
      otpRequested: false,
      error: false,
      errorMessage: null,
    });
  };


  render() {
    const { loading } = this.props.user;

    return (
      <React.Fragment>
        <Container>
          <Row>
            <Col sm={{ span: 4, offset: 4 }} style={{ marginTop: 100 }}>
              {this.state.error && (
                <Alert variant={"danger"}>{this.state.errorMessage}</Alert>
              )}
              {this.props.user.otpStatus === "VERIFY_OTP_FAILED" && (
                <Alert variant={"danger"}>
                  {"Make sure you enter a valid OTP"}
                </Alert>
              )}
              {this.props.user.otpStatus === "REQUEST_OTP_FAILED" && (
                <Alert variant={"danger"}>
                  {"Failed to send the OTP. Please try again."}
                </Alert>
              )}

              <RegisterForm
                onRequestOtp={this.getOtp}
                onChange={this.onChange}
                phoneNumber={this.state.phoneNumber}
                otpRequested={this.state.otpRequested}
                onVerifyOtp={this.verifyOtp}
                loading={loading}
                onRequestResendUserOtp={this.resendOtp}
                resetPhoneNumber={this.resetPhoneNumber}
                firstname={this.state.firstname}
                email={this.state.email}
                lastname={this.state.lastname}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

RegisterPage.propTypes = {
  user: PropTypes.any,
  history: PropTypes.any,
  actions: PropTypes.any,
  logicActions: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);