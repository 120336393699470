import React from "react";
import { connect } from "react-redux";
import {
  Spinner,
  Alert,
  Card,
  Row,
  Col,
  Container,
  Form,
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import debounce from "lodash.debounce";
import {
  getResellerDebitsInfiniteScroll,
  debitHistorySwitchTab,
} from "../../actions/resellerActivityActions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

import "../../styles/transaction.scss";
import "../../styles/pagination.scss";

class TransactionsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      productId: "1544",
      voucherCopied: false,
      validBrands: [
        {
          name: "Amazon",
          id: "1544",
        },
        {
          name: "Flipkart",
          id: "9259",
        },
        {
          name: "Amazon SV",
          id: "6861",
        },
        {
          name: "API TESTING - Processing Status",
          id: "328",
        },
        {
          name: "API TESTING - Voucher code",
          id: "325",
        },
        {
          name: "API TESTING - Claim Code",
          id: "327",
        },
        {
          name: "API TESTING - UBE",
          id: "326",
        },
        {
          name: "API TESTING - CN & PIN",
          id: "324",
        },
        {
          name: "API TESTING - Timeout Success",
          id: "338",
        },
        {
          name: "API TESTING - Timeout Failure",
          id: "336",
        },
        {
          name: "Amazon Shopping Voucher",
          id: "789",
        },
        {
          name: "API TESTING - Disabled Status",
          id: "329",
        }
      ],
      activeBrandIndex: 0,
    };
  }

  loadResellerDebits() {
    console.log(this.props.resellerDebits.lastEvaluatedKey,"coding");
    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];

    if (this.props.resellerDebits.lastEvaluatedKey !== undefined) {
      console.log(this.props.resellerDebits.lastEvaluatedKey,"lastEvaluatedKey");
      this.props.getResellerDebitsInfiniteScroll({
        //limit: 20,
        access_token: this.props.user.accessCode,
        lastEvaluatedKey: this.props.resellerDebits.lastEvaluatedKey,
        phonenumber: this.props.user.phoneNumber,
        productId: activeBrand.id,
      });
    }
  }

  componentDidMount() {
    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];

    this.props.getResellerDebitsInfiniteScroll({
      //limit: 20,
      access_token: this.props.user.accessCode,
      lastEvaluatedKey: null,
      phonenumber: this.props.user.phoneNumber,
      productId: activeBrand.id,
    });
  }

  handleBrandSelect = async (event) => {
    await this.setState({ activeBrandIndex: event.target.value.toString() });

    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];

    await this.props.debitHistorySwitchTab();

    await this.props.getResellerDebitsInfiniteScroll({
      //limit: 20,
      access_token: this.props.user.accessCode,
      lastEvaluatedKey: null,
      phonenumber: this.props.user.phoneNumber,
      productId: activeBrand.id,
    });
  };

  renderTooltip(props) {
    return (
      <Tooltip id="button-tooltip" {...props}>
        Click on voucher to Copy
      </Tooltip>
    );
  }

  render() {
    const {
      loading,
      debits,
      error,
      lastEvaluatedKey,
    } = this.props.resellerDebits;

    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];

    if (debits.length === 0 && loading === false && error === false) {
      return (
        <Container>
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h2 className="element-header">Debit History</h2>

              <Form.Group>
                <Form.Label>Select Brand</Form.Label>
                <div className="inputSelect">
                  {this.state.validBrands.map((brand, index) => (
                    <Form.Check
                      // className={activeBrand.id === brand.id ? `active` : ""}
                      onClick={this.handleBrandSelect}
                      htmlFor={`${brand.name}`}
                      id={`${brand.name}`}
                      disabled={this.state.switchDisabled}
                      type={"radio"}
                      name="activeBrand"
                      label={`${brand.name}`}
                      value={`${index}`}
                      checked={activeBrand.id === brand.id}
                    />
                  ))}
                </div>
              </Form.Group>

              <Alert variant="primary" style={{ textAlign: "center" }}>
                No Debits
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <>
        {/* <EjNavbar {...this.props} /> */}
        <Container>
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h2 className="element-header">Debit History</h2>
              <Form.Group>
                <Form.Label>Select Brand</Form.Label>
                <div className="inputSelect">
                  {this.state.validBrands.map((brand, index) => (
                    <Form.Check
                      // className={activeBrand.id === brand.id ? `active` : ""}
                      onClick={this.handleBrandSelect}
                      htmlFor={`${brand.name}`}
                      id={`${brand.name}`}
                      disabled={this.state.switchDisabled}
                      type={"radio"}
                      name="activeBrand"
                      label={`${brand.name}`}
                      value={`${index}`}
                      checked={activeBrand.id === brand.id}
                    />
                  ))}
                </div>
              </Form.Group>

              <OverlayTrigger
                      placement="middle"
                      delay={{ show: 150, hide: 200 }}
                      overlay={this.renderTooltip}
                    >
                    <Card className="resellerCard">
                      <Card.Body>
                        <Card.Text
                          style={{
                            fontWeight: "600",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span style={{ fontSize: 18, width: "15%"}}>SR.No.</span>
                          <span style={{width: "18%"}}> Validity </span>
                          <span style={{width: "18%"}}> CardNumber </span>
                          <span style={{width: "2%"}}></span>
                          <span style={{width: "15%"}}> Card Pin </span>
                          <span style={{width: "15%", float: "right", color: 'red' }}> Amount </span>
                          <span style={{width: "15%", float: "right" }}> Parent Card Balance </span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    </OverlayTrigger>

              {this.props.resellerDebits.debits.map((activity, index) => {
                return (
                  <>
                  <OverlayTrigger
                      placement="right"
                      delay={{ show: 150, hide: 200 }}
                      overlay={this.renderTooltip}
                    >
                    <Card className="resellerCard">
                      <Card.Body>
                        <Card.Text
                          style={{
                            fontWeight: "600",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span style={{width: "15%"}}>
                            <span style={{ fontSize: 18 }}>{index + 1}</span>
                            {activity.productId === "1544" ? (
                              <img
                                src="https://static.echojoy.in/logos/amazon.png"
                                class="rounded"
                              />
                            ) : null}
                            {activity.productId === "9259" ? (
                              <img
                                src="https://static.echojoy.in/logos/flipkart.png"
                                class="rounded"
                              />
                            ): null}
                            {activity.productId === "6861" ? (
                              <img
                                src="https://static.echojoy.in/logos/amazonsv.jpg"
                                class="rounded"
                              />
                            ): null}
                          </span>
                          <span style={{width: "18%"}}>
                            {new Date( activity.validity ).toLocaleString()}
                          </span>
                          <span style={{width: "20%", float: "right"}}>{activity.cardId.substr(0,2)=="CI"?"NA":activity.cardId}</span>
                          <CopyToClipboard
                            style={{width: "15%"}}
                            text={activity.pin_or_url}
                            onCopy={() => {
                              this.setState({ voucherCopied: index });
                              setTimeout(() => {
                                this.setState({ voucherCopied: null });
                              }, 1500);
                            }}
                          >
                            <div>
                              <span style={{ cursor: "pointer" }}>
                                {activity.pin_or_url}
                              </span>
                              {this.state.voucherCopied == index ? (
                                <div className="debit-vouchercopy">
                                  <span className="message">Copied!</span>
                                </div>
                              ) : null}
                            </div>
                          </CopyToClipboard>
                          <span style={{ float: "right", color: 'red', width: "15%"}}>
                            <FontAwesomeIcon icon={faMinus} size="1x" /> &#8377;
                            {activity.card_balance &&
                              parseFloat(activity.card_balance).toFixed(2)}
                          </span>
                          <span style={{ float: "right", width: "15%"}}>
                            {activity.parentCardBalance ? (
                              <>
                                {" "}
                                &#8377;
                                {activity.parentCardBalance &&
                                  parseFloat(
                                    activity.parentCardBalance
                                  ).toFixed(2)}
                              </>
                            ) : (
                              <>N/A</>
                            )}
                          </span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    </OverlayTrigger>
                  </>
                );
              })}

              <br />
              {!loading ? (
                lastEvaluatedKey && (
                  <Button
                    block
                    size="lg"
                    variant="info"
                    onClick={() => this.loadResellerDebits()}
                  >
                    {" "}
                    Load More ...{" "}
                  </Button>
                )
              ) : (
                <Col sm={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner animation="grow" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                </Col>
              )}

              <br />

              {/* {this.props.resellerDebits.loading && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner animation="grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )} */}

              {this.props.resellerDebits.error === true ? (
                <Alert variant="danger" style={{ textAlign: "center" }}>
                  Something Went Wrong.
                </Alert>
              ) : null}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    resellerDebits: state.resellerDebit,
  };
};

export default connect(mapStateToProps, {
  getResellerDebitsInfiniteScroll,
  debitHistorySwitchTab,
})(TransactionsPage);

// import React from "react";
// import {
//   Navbar,
//   Nav,
//   Container,
//   Row,
//   Col,
//   Button,
//   Alert,
//   Spinner,
//   Card,
//   Pagination,
// } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { connect } from "react-redux";
// import { EjNavbar } from "../EjNavbar";
// import { Formik, Field, Form, ErrorMessage } from "formik";
// import { getResellerDebits } from "../../actions/resellerActivityActions";
// import "../../styles/transaction.scss";
// import "../../styles/pagination.scss";

// class TransactionsPage extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       productId: "1544",
//     };
//   }
//   //   UNSAFE_componentWillMount() {
//   //     !_.get(this.props, "user.accessCode") && this.props.history.push("/login");
//   //   }

//   componentDidMount() {
//     this.props.getResellerDebits({
//       accessCode: this.props.user.accessCode,
//       phone: this.props.user.phoneNumber,
//       pageNumber: 1,
//       productId: "1544",
//     });
//   }

//   getResellerActivityPage = (pageNumber) => {
//     this.props.getResellerDebits({
//       accessCode: this.props.user.accessCode,
//       phone: this.props.user.phoneNumber,
//       pageNumber: pageNumber,
//       productId: this.state.productId,
//     });
//   };

//   paginationItems = () => {
//     const {
//       page,
//       size,
//       totalCount,
//       reseller_debits,
//     } = this.props.resellerDebit;
//     let totalPages = Math.ceil(totalCount / 100);
//     let active = page;
//     let items = [];

//     for (let number = 1; number <= totalPages; number++) {
//       if (reseller_debits.length !== 0) {
//         items.push(
//           <Pagination.Item
//             key={number}
//             active={number == active}
//             onClick={() => this.getResellerActivityPage(number)}
//           >
//             {number}
//           </Pagination.Item>
//         );
//       }
//     }
//     return <>{items}</>;
//   };

//   getActivity = () => {
//     const { loading, reseller_debits } = this.props.resellerDebit;
//     // console.log(reseller_activity);
//     if (reseller_debits.length === 0) {
//       return (
//         <Alert variant="primary" style={{ textAlign: "center" }}>
//           No Activity
//         </Alert>
//       );
//     } else {
//       return (
//         <>
//           {reseller_debits.map((card) => {
//             return (
//               <>
//                 <Card className="resellerCard">
//                   {card.productId === "1544" ? (
//                     <img
//                       src="https://static.echojoy.in/logos/amazon.png"
//                       class="rounded"
//                     />
//                   ) : (
//                     <img
//                       src="https://static.echojoy.in/logos/flipkart.png"
//                       class="rounded"
//                     />
//                   )}

//                   <Card.Body style={{ padding: "0.5rem" }}>
//                     <Card.Text style={{ fontWeight: "600" }}>
//                       <span>
//                         {new Date(parseInt(card.orderedAt)).toLocaleString()}
//                       </span>

//                       {/* <span style={{ marginLeft: "10px" }}>
//                         {card.pin_or_url}
//                       </span> */}

//                       <span style={{ marginLeft: "13%" }}>
//                         Debit: &#8377;{card.card_balance}
//                       </span>

//                       <span style={{ float: "right" }}>
//                         {card.parentCardBalance ? (
//                           <>
//                             Balance: &#8377;
//                             {parseInt(card.parentCardBalance).toFixed(2)}
//                           </>
//                         ) : null}
//                       </span>

//                       <hr
//                         style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
//                       />
//                       {card.productId === "9259" ? (
//                         <span>{card.cardId}</span>
//                       ) : null}

//                       <span
//                         style={
//                           card.productId === "1544"
//                             ? { marginLeft: "0%" }
//                             : { marginLeft: "18%" }
//                         }
//                       >
//                         {card.pin_or_url}
//                       </span>
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </>
//             );
//           })}
//           <Pagination>{this.paginationItems()}</Pagination>
//         </>
//       );
//     }
//   };

//   render() {
//     const { loading, reseller_debits } = this.props.resellerDebit;

//     return (
//       <>
//         {/* <EjNavbar {...this.props} /> */}
//         <Container>
//           <Row>
//             <Col sm={{ span: 6, offset: 3 }}>
//               <h2 className="element-header">Debit History</h2>

//               <Formik
//                 initialValues={{}}
//                 onSubmit={(value) => {
//                   if (value.brandType === "AMAZON") {
//                     this.props.getResellerDebits({
//                       accessCode: this.props.user.accessCode,
//                       phone: this.props.user.phoneNumber,
//                       pageNumber: 1,
//                       productId: "1544",
//                     });
//                     this.setState({
//                       productId: "1544",
//                     });
//                   }
//                   if (value.brandType === "FLIPKART") {
//                     this.props.getResellerDebits({
//                       accessCode: this.props.user.accessCode,
//                       phone: this.props.user.phoneNumber,
//                       pageNumber: 1,
//                       productId: "9259",
//                     });
//                     this.setState({
//                       productId: "9259",
//                     });
//                   }
//                 }}
//               >
//                 {({
//                   touched,
//                   errors,
//                   isSubmitting,
//                   values,
//                   handleChange,
//                   handleBlur,
//                 }) => (
//                   <Form>
//                     <div className="form-group">
//                       <label>Select Card</label>
//                       <Field
//                         as="select"
//                         name="brandType"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         className={`form-control ${
//                           touched.brandType && errors.brandType
//                             ? "is-invalid"
//                             : ""
//                         }`}
//                       >
//                         <option value="" label="Select a transactionType">
//                           Select a brandType
//                         </option>
//                         <option value="AMAZON" label="AMAZON">
//                           AMAZON
//                         </option>
//                         <option value="FLIPKART" label="FLIPKART">
//                           FLIPKART
//                         </option>
//                       </Field>
//                     </div>

//                     <button type="submit" className="btn btn-primary btn-block">
//                       Apply
//                     </button>
//                   </Form>
//                 )}
//               </Formik>

//               {!loading ? (
//                 <>{this.getActivity()}</>
//               ) : (
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Spinner animation="border" role="status">
//                     <span className="sr-only">Loading...</span>
//                   </Spinner>
//                 </div>
//               )}
//             </Col>
//           </Row>
//         </Container>
//       </>
//     );
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     user: state.user,
//     resellerDebit: state.resellerDebit,
//   };
// };

// export default connect(mapStateToProps, { getResellerDebits })(
//   TransactionsPage
// );

// import React from "react";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import {
//   Navbar,
//   Nav,
//   Container,
//   Row,
//   Col,
//   Image,
//   FormControl,
//   Form,
//   Spinner
// } from "react-bootstrap";
// import { Link } from "react-router-dom";

// import _ from "lodash";
// import TransactionPagination from "./TransactionsPagination";
// import { EjNavbar } from "../EjNavbar";
// import "../../styles/transaction.scss";
// import * as actions from "../../actions/cardActions";

// class TransactionsPage extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       phoneNumber: "",
//       cards: [],
//       displayCard: null,
//       pageOfTransactions: []
//     };
//     this.onChangePage = this.onChangePage.bind(this);
//   }

//   onChangePage(pageOfTransactions) {
//     // update state with new page of items
//     this.setState({ pageOfTransactions: pageOfTransactions });
//   }

//   UNSAFE_componentWillMount() {
//     !_.get(this.props, "user.accessCode") && this.props.history.push("/login");
//   }

//   componentDidMount() {
//     // Get the cards and details for this user
//     if (_.get(this.props, "user.accessCode")) {
//       this.props.actions.getCards({
//         access_token: this.props.user.accessCode,
//         number: this.props.user.phoneNumber
//       });
//     }
//   }

//   // componentDidUpdate(nextProps) {
//   //   // If cards value have changed to
//   //   // if(!this.state.displayCard) { // This can lead to error if there is no card of the give product id
//   //   //   let displayCard =  _.find(nextProps.cards.cards, {'productId': 9142, 'parent': true })
//   //   //   this.setState({ displayCard })
//   //   // }
//   // }

//   logout = async () => {
//     this.props.logout().then(() => {
//       this.props.history.push("/login");
//     });
//   };

//   render() {
//     // Move this outside
//     let cards = _.get(this.props.cards, "cards");
//     const { loading } = this.props.cards;

//     if (loading) {
//       <>
//         <EjNavbar {...this.props} />
//         <Container>
//           <Row>
//             <Col
//               sm={{ span: 6, offset: 3 }}
//               style={{
//                 marginTop: 30,
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center"
//               }}
//             >
//               <Spinner animation="border" role="status">
//                 <span className="sr-only">Loading...</span>
//               </Spinner>
//             </Col>
//           </Row>
//         </Container>
//       </>;
//     }
//     return (
//       <React.Fragment>
//         <EjNavbar {...this.props} />
//         <Container>
//           <Row>
//             <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 20 }}>
//               {this.state.pageOfTransactions.map((card, i) => {
//                 if (card.productId == "1544") {
//                   return (
//                     <div className="card" key={card.cardId}>
//                       <div>
//                         <Image
//                           src="https://static.echojoy.in/logos/amazon.png"
//                           rounded
//                         />
//                         <span className="value">&#8377;{card.card_price}</span>
//                         <CopyToClipboard
//                           text={card.pin_or_url}
//                           onCopy={() => {
//                             this.setState({ [`copied-${i}`]: true });
//                             setTimeout(() => {
//                               this.setState({ [`copied-${i}`]: false });
//                             }, 1500);
//                           }}
//                         >
//                           <span className="code">{card.pin_or_url || " "}</span>
//                         </CopyToClipboard>
//                         {this.state[`copied-${i}`] ? (
//                           <div className="feedback-amazon">
//                             <span className="message">Copied!</span>
//                           </div>
//                         ) : null}
//                       </div>
//                       <div className="date">
//                         {new Date(parseInt(card.updatedAt)).toLocaleString()}
//                       </div>
//                     </div>
//                   );
//                 } else {
//                   return (
//                     <div className="card" key={card.cardId}>
//                       <div>
//                         <Image
//                           src="https://static.echojoy.in/logos/flipkart.png"
//                           rounded
//                         />
//                         <span className="value">&#8377;{card.card_price}</span>
//                         <CopyToClipboard
//                           text={card.cardnumber}
//                           onCopy={() => {
//                             this.setState({ [`copied-${i}`]: true });
//                             setTimeout(() => {
//                               this.setState({ [`copied-${i}`]: false });
//                             }, 1500);
//                           }}
//                         >
//                           <span className="code">{card.cardnumber || " "}</span>
//                         </CopyToClipboard>
//                         <CopyToClipboard
//                           text={card.pin_or_url}
//                           onCopy={() => {
//                             this.setState({ [`copied-${i}`]: true });
//                             setTimeout(() => {
//                               this.setState({ [`copied-${i}`]: false });
//                             }, 1500);
//                           }}
//                         >
//                           <span className="code">{card.pin_or_url || " "}</span>
//                         </CopyToClipboard>
//                         {this.state[`copied-${i}`] ? (
//                           <div className="feedback">
//                             <span className="message">Copied!</span>
//                           </div>
//                         ) : null}
//                       </div>
//                       <div className="date">
//                         {new Date(parseInt(card.updatedAt)).toLocaleString()}
//                       </div>
//                     </div>
//                   );
//                 }
//               })}
//               <TransactionPagination
//                 items={cards}
//                 onChangePage={this.onChangePage}
//               />
//             </Col>
//           </Row>
//         </Container>
//       </React.Fragment>
//     );
//   }
// }

// TransactionsPage.propTypes = {
//   history: PropTypes.object,
//   user: PropTypes.object,
//   actions: PropTypes.object,
//   cards: PropTypes.array
// };

// function mapStateToProps(state) {
//   return {
//     user: state.user,
//     cards: state.cards
//   };
// }

// function mapDispatchToProps(dispatch) {
//   return {
//     actions: bindActionCreators(actions, dispatch)
//   };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
