import React from "react";
import { connect } from "react-redux";
import { getPaymentLinks } from "../../../actions/paymentActions";
import {
  Spinner,
  Alert,
  Card,
  Row,
  Col,
  Container,
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

class PaymentLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCopied: false
    }
  }

  componentDidMount() {
    this.props.getPaymentLinks({
      lastEvaluatedKey: null,
      access_token: this.props.user.accessCode,
    });
  }

  loadPaymentLinks() {
    this.props.getPaymentLinks({
      lastEvaluatedKey: null,
      access_token: this.props.user.accessCode,
    });

    if (this.props.paymentLinks.lastEvaluatedKey !== undefined) {
      this.props.getPaymentLinks({
        access_token: this.props.user.accessCode,
        lastEvaluatedKey: this.props.paymentLinks.lastEvaluatedKey,
      });
    }
  }

  render() {
    const {
      loading,
      paymentLinks,
      error,
      lastEvaluatedKey,
    } = this.props.paymentLinks;
    if (paymentLinks.length === 0 && loading === false && error === false) {
      return (
        <Container>
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <h2 className="element-header">Payment Links</h2>
              <Link to={`${this.props.location.pathname}/add/addpaymentlink`}>
                <Button block>
                  Create Payment Link
                </Button>
              </Link>
              <Alert variant="primary" style={{ textAlign: "center" }}>
                No Payment Links Available
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <Container>
        <Row>
          <Col sm={{ span: 8, offset: 2 }}>
            <h2 className="element-header">Payment Links</h2>
            <Link
                to={`${this.props.location.pathname}/add/addpaymentlink`}
                className="add-paymentlink-btn"
              >
              <Button style={{ color: "#fff !important" }} block>
                Create New Payment Link
                </Button>
            </Link>
            {paymentLinks.map((paymentLink, index) => {
              return (
                <>
                 <OverlayTrigger
                    key="right"
                    placement="right"
                    overlay={
                      !this.state.voucherCopied ?
                      <Tooltip id={`tooltip-right`}>
                        Click to Copy link.
                      </Tooltip>
                      :
                      <Tooltip id={`tooltip-right`}>
                        Copied!
                      </Tooltip>
                    }
                  >
                  <CopyToClipboard
                      text={paymentLink.paymentLink}
                      onCopy={() => {
                        this.setState({ voucherCopied: true });
                        setTimeout(() => {
                          this.setState({ voucherCopied: false });
                        }, 500);
                      }}
                    >
                      <Card className="resellerCard" style={{ padding: 15 }} key={paymentLink.paymentId}>
                        <Card.Body>
                          <Card.Text
                            style={{
                              fontWeight: "600",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          > 
                            <span>
                              {index+1}.
                            </span>
                            <span>
                              {new Date(
                                parseInt(paymentLink.createdAt)
                              ).toLocaleString()}
                            </span>

                            <span>
                              <a href={paymentLink.paymentLink} target="_blank">
                                {paymentLink.paymentLink}
                              </a>
                            </span>
                            {
                              paymentLink.paymentStatus == 'PAYMENT_SUCCESSFUL' ?
                              <span style={{ color: 'green'}}>{paymentLink.paymentStatus}</span>
                              :  
                              <span  style={{ color: '#999'}}>{paymentLink.paymentStatus}</span>
                            }
                            <span style={{ float: "right" }}>
                              &#8377;
                              {parseFloat(
                                paymentLink.paymentMethod.razorpayPaymentAmount
                              ).toFixed(2)}
                            </span>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </CopyToClipboard>
                  </OverlayTrigger>
                </>
              );
            })}

            <br />
            {!loading ? (
              lastEvaluatedKey && (
                <Button
                  block
                  size="lg"
                  variant="info"
                  onClick={() => this.loadPaymentLinks()}
                >
                  {" "}
                  Load More ...{" "}
                </Button>
              )
            ) : (
              <Col sm={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spinner animation="grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              </Col>
            )}

            <br />

            {error === true ? (
              <Alert variant="danger" style={{ textAlign: "center" }}>
                Something Went Wrong.
              </Alert>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    paymentLinks: state.paymentLinks,
    location: state.router.location,
  };
};

export default connect(mapStateToProps, { getPaymentLinks })(PaymentLinks);
