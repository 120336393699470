import axios from "axios";
import { getHostUrl } from '../utils/env';

const HOST_URL = getHostUrl();

export function placeStandardOrder(formData) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/order/standard`,
        { ...formData, orderType: 'B2B_ORDER' },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${formData.accessCode}`,
          },
        }
      )
      .then((resp) => {
        res(resp.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        // alert(JSON.stringify(error))
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(error.response);
      })
  );
}
