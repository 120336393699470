import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Tabs, Tab, Spinner, Button, ButtonGroup } from "react-bootstrap";
import { getCardsListReport } from "../../../actions/reportsActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
const moment = require('moment');

export const ButtonText = {
  GENERATING_REPORT: 'Generating Report',
  GENEREATE_REPORT: 'Generate Report',
  DOWNLOAD_REPORT: 'Downloading Report'
}

export const TransferTypes = {
  RAZORPAY: 'RAZORPAY',
  BANK: 'BANK'
}

export class BaseReportTemplate extends React.Component {
  constructor(){
    super()
  }

  handleStartDateChange = (date) => {
    this.setState({
      startDate: date
    });
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handlePhoneNumberChange = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  handleBrandChange = (event) => {
    this.setState({ productId: event.target.value });
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleTransferChange = (event) => {
    this.setState({ productId: event.target.value });
  };

  convertDateIntoString = (dateObj) => {
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    // console.log(newStartdate);
    let hours = dateObj.getHours();
    let mins = dateObj.getMinutes();
    let seconds = dateObj.getSeconds();

    return (
      year + "-" + month + "-" + day + " " + hours + ":" + mins + ":" + seconds
    );
  };

  generateReport = async (e) => {
    this.setState({ 
      loading: true,
      message: ButtonText.GENERATING_REPORT
    })

    e.preventDefault();

    const startDate = this.convertDateIntoString(this.state.startDate);
    const endDate = this.convertDateIntoString(this.state.endDate);
    const apiObj = {
      fromDate: startDate,
      toDate: endDate,
      productId: this.state.productId,
      access_token: this.props.user.accessCode,
    };
    const data = await this.props.getCardsListReport(apiObj);

    this.setState({ message: ButtonText.DOWNLOAD_REPORT })    
    const url = data.data.url;
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.setState({ loading: false, message: ButtonText.GENEREATE_REPORT })
  };

  getTodayReport(e) {
    let todayStart = new Date();
    todayStart.setHours(0,0,0,0);

    const startDate = todayStart 
    const endDate = new Date(Date.now())

    this.setState({ startDate, endDate }, () => {
      this.generateReport(e)  
    })
  }

  getThisWeekReport(e) {
    let startDate = new Date();
    let n = startDate.getDay();
    startDate.setDate(startDate.getDate() - n);

    const endDate = new Date(Date.now())

    this.setState({ startDate, endDate }, () => {
      this.generateReport(e)  
    })
  }
  
  getLastOneYear(e) {
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 12);

    const endDate = new Date(Date.now())

    this.setState({ startDate , endDate })
    this.generateReport(e)
  }

  getThisMonth(e, n) {
    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 12);

    const endDate = Date.now()

    this.setState({ startDate, endDate }, () => {
      this.generateReport(e)  
    })
  }

  getLastNDays(e, n) {
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - n);

    const endDate = new Date(Date.now())

    this.setState({ startDate, endDate }, () => {
      this.generateReport(e)  
    })
  }

  render() {
    const { loading } = this.state;

    return (
      <>
        <Form>
          <Row> 
            <Col md={{ span: 12, offset: 0 }}>
              <ButtonGroup info size="sm" style={{ marginBottom: 20, width: '100%' }}>
                  <Button variant="secondary" onClick={(e) => { this.getTodayReport(e) }}>Today</Button>
                  <Button variant="secondary" onClick={(e) => { this.getThisWeekReport(e) }}>This Week</Button>
                  <Button variant="secondary" onClick={(e) => { this.getLastNDays(e, 3) }}>Last 3 days</Button>
                  <Button variant="secondary" onClick={(e) => { this.getLastNDays(e, 7) }}>Last 7 days</Button>
                  <Button variant="secondary" onClick={(e) => { this.getThisMonth(e) }}>This Month</Button>
                  <Button variant="secondary" onClick={(e) => { this.getLastOneYear(e) }} >Last One Year</Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Form.Row>
            <Col className="form-group">
              <label>Start Date</label>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleStartDateChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
                className="form-control"
              />
            </Col>
            <Col className="form-group">
              <label>End Date</label>
              <DatePicker
                selected={this.state.endDate}
                onChange={this.handleEndDateChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
                className="form-control asd"
              />
            </Col>
          </Form.Row>
          {/* <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              className="form-control"
              onChange={this.handlePhoneNumberChange}
            />
          </div> */}
          <div class="form-group">
            <label for="inputState">Select Brand</label>
            <select
              id="inputState"
              class="form-control"
              onChange={this.handleBrandChange}
            >
              <option value="1544">Amazon</option>
              <option value="9259">Flipkart</option>
            </select>
          </div>
          <Button
            type="submit"
            className="btn btn-primary btn-block"
            onClick={this.generateReport}
            disabled={this.state.loading}
          > 
            {this.state.loading}
            { loading && 
                <Spinner animation="grow" role="status" size="sm" style={{ marginRight: 10 }}>
                  <span className="sr-only">Loading...</span>
                </Spinner>
            }
            {this.state.message}
          </Button>
        </Form>
      </>
    );
  }
}
