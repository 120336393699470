import React from "react";
import { connect } from "react-redux";
import { ListGroup, Button } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import { deletePhoneNumber } from "../../actions/phoneActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile } from "@fortawesome/free-solid-svg-icons";

class PhoneNumber extends React.Component {
  deleteNumber = phone => {
    this.props.deletePhoneNumber({
      removeNumber: phone.phone_number,
      accessCode: this.props.user.accessCode
    });
  };

  render() {
    // const { deleteBtnLoading } = this.props.phoneNumbers;
    return (
      <Link
        to={`/useractivity/${this.props.phone_number.id}`}
        className="phonenumber"
        key={this.props.phone_number.id}
      >
        <div>
          <FontAwesomeIcon icon={faMobile} size="1x" />
          <CopyToClipboard text={this.props.phone_number.phone_number}>
            <span className="number">
              {this.props.phone_number.phone_number}
            </span>
          </CopyToClipboard>
        </div>
        <Button
          variant="danger"
          onClick={() => this.deleteNumber(this.props.phone_number)}
          style={{ float: "right", fontSize: 12 }}
        >
          Delete
        </Button>
      </Link>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    router: state.router
    // phoneNumbers: state.phoneNumbers
  };
};

export default connect(mapStateToProps, { deletePhoneNumber })(PhoneNumber);
