import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

const RegisterForm = (props) => {
  return (
    <React.Fragment>
      {!props.otpRequested ? (
        <Form onSubmit={(e) => props.onRequestOtp(e)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              onChange={(e) => props.onChange("firstname", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              onChange={(e) => props.onChange("lastname", e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Phone Number"
              value={props.phoneNumber}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  props.onChange("phoneNumber", e.target.value);
                }
              }}
            />
            <Form.Text className="text-muted">
              Please enter a 10-digit Indian number
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email"
              onChange={(e) => props.onChange("email", e.target.value)}
            />
          </Form.Group>
          <Button
            variant="primary"
            block
            type="submit"
            disabled={props.loading}
          >
            {props.loading ? (
              <span style={{ marginRight: "10px" }}>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            ) : null}
            Register
          </Button>
        </Form>
      ) : (
        <>
        <Form onSubmit={(e) => props.onVerifyOtp(e)}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                defaultValue={props.phoneNumber}
                type="text"
                placeholder="Enter Phonenumber"
              />
              <Form.Text className="text-muted">
                Please enter 10 digit indian number
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>OTP</Form.Label>

              <Form.Control
                type="text"
                placeholder="Enter OTP"
                onChange={(e) => {
                  props.onChange("otp", e.target.value);
                }}
              />
              <Form.Text className="text-muted">
                Enter your 6 digit OTP
              </Form.Text>
            </Form.Group>

            <Button
              variant="primary"
              block
              type="submit"
              disabled={props.loading}
            >
              {props.loading === true ? (
                <span style={{ marginRight: "10px" }}>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
              ) : null}
              Verify OTP
            </Button>
            <p
              style={{
                textAlign: "center",
                marginTop: "20px",
                fontWeight: "600",
                cursor: "pointer",
              }}
              onClick={(e) => props.onRequestResendUserOtp(e)}
            >
              Resend OTP
            </p>
            <p
              style={{
                textAlign: "center",
                marginTop: "20px",
                fontWeight: "600",
                cursor: "pointer",
              }}
              onClick={(e) => props.resetPhoneNumber(e)}
            >
              Reset PhoneNumber
            </p>
          </Form>
        </>
      )}
    </React.Fragment>
  );
};

RegisterForm.propTypes = {
  onRequestOtp: PropTypes.func.isRequired,
  onVerifyOtp: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  otpRequested: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
};

export default RegisterForm;