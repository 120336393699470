import React from "react";
import { connect } from "react-redux";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Button,
  Form,
  ListGroup,
  FormControl,
  Alert,
  Spinner,
} from "react-bootstrap";

import { Link, Route, Switch } from "react-router-dom";
import { EjNavbar } from "../EjNavbar";
import "../../styles/phonelist.scss";
// import ResellerActivity from "./ResellerActivity";

class ResellerActivityPage extends React.Component {
  render() {
    const reseller = this.props.data.phone_numbers.find(
      (reseller) => reseller.id === parseInt(this.props.match.params.id)
    );

    if (reseller) {
      return (
        <>
          <ResellerActivity reseller={reseller} />
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    phoneNumbers: state.phoneNumbers,
    user: state.user,
    flashMsg: state.flashMsg,
    router: state.router,
  };
};

export default connect(mapStateToProps, null)(ResellerActivityPage);
