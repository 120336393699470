import axios from "axios";
import * as _ from "lodash";
import { getHostUrl } from "../utils/env";

const HOST_URL = getHostUrl();

export function getResellerActivityAPI(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/resellers/getActivity`,
        {
          phonenumber: data.phone,
          brandType: data.brandType,
          page: data.pageNumber,
          size: 100,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.accessCode}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        // alert(JSON.stringify(error))
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function getResellerDebitsAPI(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/resellers/debits`,
        {
          phonenumber: data.phone,
          productId: data.productId,
          page: data.pageNumber,
          size: 100,
        },

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.accessCode}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        // alert(JSON.stringify(error))
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function getResellerDebitsInfiniteScrollAPI(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/resellers/debits/pagination`,
        {
          phonenumber: data.phonenumber,
          lastEvaluatedKey: data.lastEvaluatedKey,
          productId: data.productId,
        },
        // 9553255654
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        // alert(JSON.stringify(error))
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(error);
      })
  );
}

// export function getResellerActivityAPI(data) {
//   return new Promise((res, rej) =>
//     axios
//       .get(
//         `${HOST_URL}/resellers/getActivity/${data.phone}?page=${data.pageNumber}&size=100`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `JWT ${data.accessCode}`
//           }
//         }
//       )
//       .then(response => {

//         res(response);
//       })
//       .catch(error => {
//         rej(new Error(error));
//       })
//   );
// }

// export function getResellerDebitsAPI(data) {
//   return new Promise((res, rej) =>
//     axios
//       .get(
//         `${HOST_URL}/resellers/debits/${data.phone}?page=${data.pageNumber}&size=100`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `JWT ${data.accessCode}`
//           }
//         }
//       )
//       .then(response => {
//         res(response);
//       })
//       .catch(error => {
//         // alert(JSON.stringify(error))
//         rej(new Error(error));
//       })
//   );
// }
