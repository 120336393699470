import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Table, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { getRefunds } from "../../actions/refundActions";

const Refunds = ({ getRefunds, user, refundsReducer }) => {
  useEffect(() => {
    const { accessCode: access_token } = user;
    if (access_token) getRefunds({ access_token });
    return () => {};
  }, []);

  if (refundsReducer.loading) {
    return (
      <Row className="justify-content-center p-5">
        <Col className="col-auto">
          <Spinner animation="grow" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    );
  }

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h3>Refunds</h3>
          {refundsReducer.refunds.length ? (
            <Table striped hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Phone number</th>
                  <th>Amount</th>
                  <th>Refund status</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              <tbody>
                {refundsReducer.refunds.map((refund, index) => (
                  <tr key={refund.paymentId}>
                    <td>{index + 1}</td>
                    <td>{refund.phonenumber}</td>
                    <td>{refund.amount}</td>
                    <td>{refund.refundStatus}</td>
                    <td>{refund.createdDate}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <h5 className="text-center p-5">No data found</h5>
          )}
        </Col>
      </Row>
    </Container>
  );
};

Refunds.propTypes = {
  getRefunds: PropTypes.func,
  user: PropTypes.instanceOf(Object),
  refundsReducer: PropTypes.instanceOf(Object),
};

Refunds.defaultProps = {
  getRefunds: () => {},
  user: {},
  refundsReducer: {},
};

const mapStateToProps = ({ user, refunds: refundsReducer }) => ({
  user,
  refundsReducer,
});

const mapDispatchToProps = {
  getRefunds,
};

export default connect(mapStateToProps, mapDispatchToProps)(Refunds);
