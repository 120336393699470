import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Navbar, Nav, Container, Row, Col, Button, Alert } from 'react-bootstrap';
import _ from 'lodash';

import * as actions from '../../actions/loginActions';
import { EjNavbar } from '../EjNavbar';

class LogoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

	UNSAFE_componentWillMount() {
		if (_.get(this.props, 'user.accessCode')) {
			this.props.actions.logout('');
      		this.props.history.push('/');
		}
	}

  render() {
    return (
      <>
        <EjNavbar  {...this.props} />
      </>
    );
  }
}

LogoutPage.propTypes = {
	user: PropTypes.any,
	history: PropTypes.any,
	actions: PropTypes.any,
	loginActions: PropTypes.any
};

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
