import React from "react";
import { Link, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import PaymentLinks from "./PaymentLinks";
import AddPaymentLink from "./AddPaymentLink";

class PaymentLinksPage extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/paymentlinks" component={PaymentLinks} />
        <Route path="/paymentlinks/add" component={AddPaymentLink} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
  };
};

export default connect(mapStateToProps, null)(PaymentLinksPage);
