import { combineReducers } from "redux";
import cards from "./cardsReducer";
import user from "./userReducer";
import phoneNumbers from "./phonenumbersReducer";
import resellerActivity from "./resellerActivity";
import flashMsg from "./flashMsgReducer";
import resellerDebit from "./resellerDebit";
import selfPayments from "./selfPaymentReducer";
import reports from "./reports";
import refunds from "./refundsReducer";
import paymentLinks from "./paymentLinksReducer";
import { connectRouter } from "connected-react-router";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    cards,
    phoneNumbers,
    resellerActivity,
    resellerDebit,
    selfPayments,
    reports,
    paymentLinks,
    flashMsg,
    refunds,
  });

export default rootReducer;
