import * as actionTypes from "../actions/paymentActions";
import * as actionType from "../actions/loginActions";

const initialState = {
  loading: false,
  error: null,
  status: false,
  variant: "",
  totalAmount: null,
  success: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // phonenumber list
    case actionTypes.REQUEST_SELF_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REQUEST_SELF_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
        variant: "success",
        totalAmount: action.data.totalAmount,
        success: true,
      };

    case actionTypes.REQUEST_SELF_PAYMENT_FAILED:
      return {
        ...state,
        loading: false,
        status: true,
        variant: "danger",
        success: false,
        error: true,
      };

    case actionTypes.CLOSE_ALERT:
      return {
        ...state,
        loading: false,
        error: null,
        status: false,
        variant: "",
        totalAmount: null,
        success: false,
      };

    case actionTypes.RESET_ADD_MONEY_FORM:
      return {
        loading: false,
        error: null,
        status: false,
        variant: "",
        totalAmount: null,
        success: false,
      };

    case actionType.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
