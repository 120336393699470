import * as actionTypes from "../actions/phoneActions";
import * as actionType from "../actions/loginActions";

const initialState = {
  loading: false,
  phone_numbers: [],
  error: null,
  addBtnLoading: false,
  deleteBtnLoading: false,
  otpLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    // phonenumber list
    case actionTypes.REQUEST_PHONENUMBER_LIST:
      return {
        ...state,
        loading: true
      };
    case actionTypes.REQUEST_PHONENUMBER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        phone_numbers: action.phoneNumbers
      };

    case actionTypes.REQUEST_PHONENUMBER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        phone_numbers: initialState.phone_numbers
      };

    //delete
    case actionTypes.DELETE_PHONE_NUMBER:
      //const index = state.phone_numbers.findIndex((phone) => phone.phone_number === action.phone);
      return {
        ...state,
        deleteBtnLoading: true
      };
    case actionTypes.DELETE_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteBtnLoading: false,
        phone_numbers: state.phone_numbers.filter(phone => {
          return phone.phone_number !== action.phone;
        })
      };

    // REQUEST_RESELLER_OTP
    case actionTypes.REQUEST_RESELLER_OTP:
      return {
        ...state,
        addBtnLoading: true
      };

    case actionTypes.REQUEST_RESELLER_OTP_SUCCESS:
      return {
        ...state,
        addBtnLoading: false
      };

    case actionTypes.REQUEST_RESELLER_OTP_FAIL:
      return {
        ...state,
        addBtnLoading: false
      };

    // phone add
    case actionTypes.ADD_PHONE_NUMBER:
      return {
        ...state,
        error: null,
        addBtnLoading: true
      };

    case actionTypes.ADD_PHONE_NUMBER_SUCCESS:
      const phone_obj = {
        phone_number: action.phone,
        id: state.phone_numbers.length
      };
      return {
        ...state,
        loading: false,
        phone_numbers: [...state.phone_numbers, phone_obj],
        error: null,
        addBtnLoading: false
      };

    case actionTypes.ADD_PHONE_NUMBER_ERROR:
      return {
        ...state,
        loading: false,
        phone_numbers: state.phone_numbers,
        error: action.error,
        addBtnLoading: false
      };

    case actionType.LOGOUT:
      return {
        ...state,
        loading: false,
        phone_numbers: [],
        error: null
      };

    default:
      return state;
  }
}
