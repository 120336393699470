import React from "react";
import { connect } from "react-redux";
import {
  Spinner,
  Alert,
  Form,
  Row,
  Col,
  Container,
  Button,
  Card,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { addPaymentLink } from "../../../actions/paymentActions";
import { CopyToClipboard } from "react-copy-to-clipboard";

class AddPaymentLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      razorpayID: "",
      btnText: "Create a payment Link",
      validBrands: [
        {
          name: "Amazon",
          id: "1544",
          img: "https://static.echojoy.in/logos/amazon.png",
          custom_denominations: [10, 100, 200, 500, 1000, 2000, 5000, 10000],
          minValue: 10,
          maxValue: 10000,
        },
        {
          name: "Flipkart",
          id: "9259",
          img: "https://static.echojoy.in/logos/flipkart.png",
          custom_denominations: [50, 100, 200, 500, 1000, 2000, 5000, 10000],
          minValue: 10,
          maxValue: 10000,
        },
      ],
      activeBrandIndex: 0,
      value: 0,
      disabled: false,
    };
  }

  handleBrandSelect = (event) => {
    this.setState({ activeBrandIndex: event.target.value.toString() });
  };

  onInputChange = (e) => {
    this.setState({ value: e.target.value });
  };

  createLink = async () => {
    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];
    let activeCard = this.displayCard();

    // if (
    //   this.state.value < activeBrand.minValue ||
    //   this.state.value > activeBrand.maxValue
    // ) {
    //   this.setState({
    //     alert: true,
    //     alertMessage: `Please enter amount between ${activeBrand.minValue} to ${activeBrand.maxValue}`,
    //     disabled: !this.state.disabled,
    //   });

    //   setTimeout(() => {
    //     this.setState({
    //       alert: false,
    //       alertMessage: "",
    //       disabled: !this.state.disabled,
    //     });
    //   }, 4000);
    // } else {
      await this.props.addPaymentLink({
        amount: this.state.value,
        cardId: activeCard.cardId,
        productId: activeCard.productId,
        access_token: this.props.user.accessCode,
      });
    // }
  };

  onSelectValue = (e, c) => {
    this.setState({
      value: e.target.getAttribute("data-value"),
      activeCustomDenominations: c,
    });
  };

  displayCard() {
    if (this.state.activeBrandIndex == 0) {
      return _.find(this.props.cards.cards, (c) => { 
        if(c.productId.toString() == '9142' &&  c.parent) return c
      });
    } else if (this.state.activeBrandIndex == 1) {
      return _.find(this.props.cards.cards,(c) => { 
        if(c.productId.toString() == '9146' &&  c.parent) return c
      });
    }
  }

  render() {
    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];
    const { loading, paymentLink, error } = this.props.paymentLinks;
    return (
      <Container>
        <Row>
          <Col sm={{ span: 6, offset: 3 }}>
            <h2 className="element-header">New Payment Link</h2>
            {this.state.alert && (
              <Alert variant={"danger"}>{this.state.alertMessage} </Alert>
            )}
            <Form.Group>
              <Form.Label>Select Brand</Form.Label>
              <div className="inputSelect">
                {this.state.validBrands.map((brand, index) => (
                  <Form.Check
                    // className={activeBrand.id === brand.id ? `active` : ""}
                    onChange={this.handleBrandSelect}
                    htmlFor={`${brand.name}`}
                    id={`${brand.name}`}
                    disabled={this.state.switchDisabled}
                    type={"radio"}
                    name="activeBrand"
                    label={`${brand.name}`}
                    value={`${index}`}
                    checked={activeBrand.id === brand.id}
                  />
                ))}
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Enter a valid amount</Form.Label>
              <Form.Control
                type="text"
                value={this.state.value}
                placeholder=""
                onChange={this.onInputChange}
              />
            </Form.Group>
            <ul className="custom">
              {activeBrand.custom_denominations.map((c) => {
                return (
                  <li
                    data-value={c}
                    onClick={(e) => this.onSelectValue(e, c)}
                    className={
                      this.state.activeCustomDenominations === c ? "active" : ""
                    }
                    style={
                      this.state.activeCustomDenominations === c
                        ? { backgroundColor: "#007bff", color: "#ffffff" }
                        : {}
                    }
                  >
                    {c}
                  </li>
                );
              })}
            </ul>

            <Button
              block
              disabled={loading === true ? true : false}
              variant="danger"
              onClick={this.createLink}
            >
              {loading === true ? (
                <span style={{ marginRight: "10px" }}>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                </span>
              ) : null}

              {this.state.btnText}
            </Button>

            {!_.isEmpty(paymentLink) ? (
              <OverlayTrigger
                    key="right"
                    placement="right"
                    overlay={
                      !this.state.voucherCopied ?
                      <Tooltip id={`tooltip-right`}>
                        Click to Copy link.
                      </Tooltip>
                      :
                      <Tooltip id={`tooltip-right`}>
                        Copied!
                      </Tooltip>
                    }
                  >
                  <CopyToClipboard
                      text={paymentLink.paymentLink}
                      onCopy={() => {
                        this.setState({ voucherCopied: true });
                        setTimeout(() => {
                          this.setState({ voucherCopied: false });
                        }, 500);
                      }}
                    >
              <Card className="resellerCard" key={paymentLink.paymentId}>
                <Card.Body>
                  <Card.Text
                    style={{
                      fontWeight: "600",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: 15
                    }}
                  >
                    <span>
                      {new Date(
                        parseInt(paymentLink.createdAt)
                      ).toLocaleString()}
                    </span>

                    <span>
                      <a href={paymentLink.paymentLink} target="_blank">
                        {paymentLink.paymentLink}
                      </a>
                    </span>

                    <span style={{ float: "right" }}>
                      &#8377;
                      {parseFloat(
                        paymentLink.paymentMethod.razorpayPaymentAmount
                      ).toFixed(2)}
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
              </CopyToClipboard>
                  </OverlayTrigger>
            ) : null}

            <br />

            {error === true ? (
              <Alert variant="danger" style={{ textAlign: "center" }}>
                Something Went Wrong.
              </Alert>
            ) : null}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    location: state.router.location,
    user: state.user,
    cards: state.cards,
    paymentLinks: state.paymentLinks,
  };
};

export default connect(mapStateToProps, { addPaymentLink })(AddPaymentLink);
