import React from "react";
import { connect } from "react-redux";
import { EjNavbar } from "../EjNavbar";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Image,
  Button,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";
import { fetchPaymentId } from "../../actions/paymentActions";

class BulkCoupons extends React.Component {
  state = {
    activeBrandIndex: 0,
    validBrands: [
      {
        name: "Amazon",
        id: "1544",
        img: "https://static.echojoy.in/logos/amazon.png",
        custom_denominations: [10, 100, 200, 500, 1000, 2000, 5000, 10000],
        minValue: 10,
        maxValue: 10000,
      },
      {
        name: "Flipkart",
        id: "9259",
        img: "https://static.echojoy.in/logos/flipkart.png",
        custom_denominations: [50, 100, 200, 500, 1000, 2000, 5000, 10000],
        minValue: 10,
        maxValue: 10000,
      },
    ],
    redeemValue: 0,
    noOfVouchers: 0,
    btnText: "Redeem Now",
    alertMessage: "",
  };

  handleBrandSelect = (event) => {
    this.setState({ activeBrandIndex: event.target.value.toString() });
  };
  onInputChange = (e) => {
    this.setState({ redeemValue: e.target.value });
  };
  onNoOfVoucherChange = (e) => {
    this.setState({ noOfVouchers: e.target.value });
  };
  displayCard() {
    if (this.state.activeBrandIndex == 0) {
      return _.find(this.props.cards.cards, { productId: 9142, parent: true });
    } else if (this.state.activeBrandIndex == 1) {
      return _.find(this.props.cards.cards, { productId: 9146, parent: true });
    }
  }
  generateBulkVouchers = async () => {
    let paymentDetails;
    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];
    let displayCard = this.displayCard();

    if (
      this.state.redeemValue < activeBrand.minValue ||
      this.state.redeemValue > activeBrand.maxValue
    ) {
      this.setState({
        btnText: "Redeem Now",
        alert: true,
        alertMessage: `Please enter amount between ${activeBrand.minValue} to ${activeBrand.maxValue}`,
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          alertMessage: "",
          disabled: !this.state.disabled,
        });
      }, 4000);
      return;
    } else {
      this.setState({
        disabled: !this.state.disabled,
        btnText: "Deducting from Card ...",
        loading: true,
      });

      let paymentId;
      try {
        paymentId = await this.props.fetchPaymentId({
          accessCode: this.props.user.accessCode,
        });
      } catch (e) {
        console.log(e);
      }

      paymentDetails = await {
        paymentId: paymentId.paymentId, // Get Payment Id before
        totalAmount: this.state.redeemValue,
        walletPaymentAmount: 0,
        cardId: displayCard.cardId,
        cardPaymentAmount: this.state.redeemValue,
        transactionId: null,
        razorpayPaymentAmount: 0,
        productId: activeBrand.id,
        accessCode: this.props.user.accessCode,
      };

      console.log(paymentDetails);

      // Try for Paymetn Id
      //   let paymentId;
    }
  };
  render() {
    let displayCard = this.displayCard();
    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];

    return (
      <>
        <Container>
          <Row>
            <Col sm={{ span: 6, offset: 3 }}>
              <h2 className="element-header">Bulk Coupons</h2>
              {this.state.alert && (
                <Alert variant={"danger"}>{this.state.alertMessage} </Alert>
              )}
              <div className="card-details">
                <p className="card-balance">
                  Registered Number: {this.props.user.phoneNumber}
                </p>
                <p className="card-balance">
                  Card Number: {displayCard.cardId}
                </p>
                <p className="card-balance">
                  Balance: &#8377; {displayCard.card_balance}
                </p>
              </div>

              <Form.Group>
                <Form.Label>Select Brand</Form.Label>
                <div className="inputSelect">
                  {this.state.validBrands.map((brand, index) => (
                    <Form.Check
                      // className={activeBrand.id === brand.id ? `active` : ""}
                      onClick={this.handleBrandSelect}
                      htmlFor={`${brand.name}`}
                      id={`${brand.name}`}
                      disabled={this.state.switchDisabled}
                      type={"radio"}
                      name="activeBrand"
                      label={`${brand.name}`}
                      value={`${index}`}
                      checked={activeBrand.id === brand.id}
                    />
                  ))}
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Enter a Number of Vouchers you wish to generate
                </Form.Label>
                <Form.Control
                  type="text"
                  value={this.state.redeemValue}
                  placeholder=""
                  onChange={this.onInputChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Enter a Value to Redeem</Form.Label>
                <Form.Control
                  type="text"
                  value={this.state.noOfVouchers}
                  placeholder=""
                  onChange={this.onNoOfVoucherChange}
                />
              </Form.Group>
              <Button
                block
                disabled={this.state.disabled}
                variant="danger"
                onClick={() => {
                  this.generateBulkVouchers();
                  this.setState({ switchDisabled: true });
                }}
              >
                {this.state.loading === true ? (
                  <span style={{ marginRight: "10px" }}>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                ) : null}

                {this.state.btnText}
              </Button>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cards: state.cards,
    phoneNumbers: state.phoneNumbers,
    // loading: state.phoneNumbers.loading
  };
};

export default connect(mapStateToProps, { fetchPaymentId })(BulkCoupons);
