import axios from "axios";
import * as _ from "lodash";
import { getHostUrl } from "../utils/env";

const HOST_URL = getHostUrl();

export function getCardsByTimeIntervalProductIdAndNumber(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/report/ej/allCardsProductPhoneReport`,
        {
          fromDate: data.fromDate,
          toDate: data.toDate,
          productId: data.productId,
          phonenumber: data.phonenumber,
          email: data.email
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(error);
      })
  );
}

export function getCreditsReportByPhoneNumber(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/report/resellerCreditReport`,
        {
          fromDate: data.fromDate,
          toDate: data.toDate,
          phonenumber: data.phonenumber,
          email: data.email
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(error);
      })
  );
}
