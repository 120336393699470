import * as actionTypes from "../actions/resellerActivityActions";
import * as actionType from "../actions/loginActions";

const initialState = {
  loading: false,
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // phonenumber list

    case actionType.LOGOUT:
      return { ...initialState };

    case actionTypes.REQUEST_CARD_LIST_REPORT_FAILED:
      return {
        ...state,
        error: true,
      };
    case actionTypes.REMOVE_REQUEST_CARD_LIST_REPORT_ERROR_MSG:
      return {
        ...state,
        error: false,
      };
    default:
      return state;
  }
}
