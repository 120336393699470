import {
  REQUEST_REFUNDS,
  REQUEST_REFUNDS_SUCCESS,
  REQUEST_REFUNDS_FAILED,
} from "../actions/refundActions";

import { LOGOUT } from "../actions/loginActions";

const initialState = {
  refunds: [],
  loading: true,
  error: null,
};

export default function refundsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_REFUNDS:
      return { ...state, loading: true };
    case REQUEST_REFUNDS_FAILED:
      return { ...state, loading: false, error: payload.error };
    case REQUEST_REFUNDS_SUCCESS:
      return {
        ...state,
        loading: false,
        refunds: [...payload],
      };
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
