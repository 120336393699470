import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Image,
  Button,
  Form,
  Alert,
  Spinner,
  Overlay,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
// import Overlay from 'react-bootstrap/Overlay'
import _ from "lodash";
import { Link } from "react-router-dom";

import { EjNavbar } from "../EjNavbar";
import * as actions from "../../actions/cardActions";
import * as loginActions from "../../actions/loginActions";
import * as paymentActions from "../../actions/paymentActions";
import * as orderActions from "../../actions/orderActions";
import * as phoneActions from "../../actions/phoneActions";
import "../../styles/nav.scss";
import "../../../assets/deal.jpeg";

// Move this to CSS
class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      cards: [],
      redeemValue: 0,
      displayCard: null,
      voucher: null,
      disabled: false,
      btnText: "Redeem Now",
      alert: false,
      alertMessage: "",
      dummyPhoneNumber: "",
      activeCustomDenominations: "",
      loading: false,
      validBrands: [
        {
          name: "Amazon",
          id: "1544",
          img: "https://static.echojoy.in/logos/amazon.png",
          custom_denominations: [10, 100, 200, 500, 1000, 2000, 5000, 10000],
          sku: "EGCGBZAGG001",
          minValue: 10,
          maxValue: 10000,
        },
        {
          name: "Flipkart",
          id: "9259",
          img: "https://static.echojoy.in/logos/flipkart.png",
          custom_denominations: [50, 100, 200, 500, 1000, 2000, 5000, 10000],
          sku: "FLIPKART",
          minValue: 50,
          maxValue: 10000,
        },
        {
          name: "Amazon SV",
          id: "6861",
          img: "https://static.echojoy.in/logos/amazonsv.png",
          custom_denominations: [10, 100, 200, 500, 1000, 2000, 5000, 10000],
          sku: "EGCGBZAGG002",
          minValue: 10,
          maxValue: 10000,
        },
        {
          name: "API TESTING - Processing Status",
          id: "328",
          img: "https://gbdev.s3.amazonaws.com/uat/product/PROCESSINGSTS/d/mobile/328_microsite.jpg",
          custom_denominations: ["100","200","300","400","500","600","1000","10000"],
          sku: "PROCESSINGSTS",
          minValue:100,
          maxValue: 100000,
      },
      {
          name: "API TESTING - Voucher code",
          id: "325",
          img: "https://gbdev.s3.amazonaws.com/uat/product/VOUCHERCODE/d/mobile/325_microsite.jpg",
          custom_denominations: ["100"], // "1","250","1000","2000","4000","5000","6000","7000","8000","9000","10000"],
          sku: "VOUCHERCODE",
          minValue: 10,
          maxValue:  10000,
      },
      {
          name: "API TESTING - Claim Code",
          id: "327",
          img: "https://gbdev.s3.amazonaws.com/uat/product/CLAIMCODE/d/mobile/327_microsite.png",
          custom_denominations: ["100","1000","2000","3000","4000","5000","6000","7000","8000","9000","10000"],
          sku: "CLAIMCODE",
          minValue:100,
          maxValue: 10000,
      },
      {
          name: "API TESTING - UBE",
          id: "326",
          img: "https://gbdev.s3.amazonaws.com/uat/product/UBEFLOW/d/mobile/326_microsite.jpg",
          custom_denominations: ["100","1000","2000","3000","4000","5000","6000","7000","8000","9000","10000"],
          sku: "UBEFLOW",
          minValue:100,
          maxValue: 10000,
      },
      {
          name: "API TESTING - CN & PIN",
          id: "324",
          img: "https://gbdev.s3.amazonaws.com/uat/product/CNPIN/d/mobile/324_microsite.png",
          custom_denominations: ["10","100","500","1000","2000","3000","4000","5000","6000","7000","8000","9000","10000"],
          sku: "CNPIN",
          minValue: 10,
          maxValue:  10000,
      },
      {
          name: "API TESTING - Timeout Success",
          id: "338",
          img: "https://gbdev.s3.amazonaws.com/uat/product/testsuccess001/d/mobile/338_microsite.jpg",
          custom_denominations: ["10","100","1000","2000","3000","4000","5000","6000","7000","8000","9000","10000"],
          sku: "testsuccess001",
          minValue: 10,
          maxValue:  10000,
      },
      {
          name: "API TESTING - Timeout Failure",
          id: "336",
          img: "https://gbdev.s3.amazonaws.com/uat/product/APITESTTIMFAIL/d/mobile/336_microsite.jpg",
          custom_denominations: ["10","100","1000","2000","3000","4000","5000","6000","7000","8000","9000","10000"],
          sku: "APITESTTIMFAIL",
          minValue: 10,
          maxValue:  10000,
      },
      {
          name: "Amazon Shopping Voucher",
          id: "789",
          img: "https://gbdev.s3.amazonaws.com/uat/product/ASVAMZNB2B/d/mobile/789_microsite.jpg",
          custom_denominations: [],
          sku: "ASVAMZNB2B",
          minValue: 1,
          maxValue: 10000,
      },
      {
          name: "API TESTING - Disabled Status",
          id: "329",
          img: "https://gbdev.s3.amazonaws.com/uat/product/DISABLEDSTS/d/mobile/329_microsite.jpg",
          custom_denominations: ["100","1000","2000","3000","4000","5000","6000","7000","8000","9000","10000"],
          sku: "DISABLEDSTS",
          minValue: 100,
          maxValue: 10000,
      },
        // {
        //   name: "JioMart",
        //   id: "279",
        //   img: "https://static.echojoy.in/logos/nyka.webp",
        //   custom_denominations: [100, 200, 500, 1000, 2000, 5000, 10000],
        //   minValue: 100,
        //   maxValue: 10000,
        // },
        // {
        //   name: "BigBasket",
        //   id: "733",
        //   img: "https://static.echojoy.in/logos/bigbasket.webp",
        //   custom_denominations: [500, 1000, 2000, 5000, 10000],
        //   minValue: 500,
        //   maxValue: 10000,
        // },
      ],
      activeBrandIndex: 0,
      voucherCopied: false,
      // text: false
    };
  }

  componentDidMount() {
    // Get the cards and details for this user
    if (_.get(this.props, "user.accessCode")) {
      this.props.actions.getCards({
        access_token: this.props.user.accessCode,
        number: this.props.user.phoneNumber,
        pageNumber: 1,
      });

      // this.props.phoneActions.requestPhoneNumbers({
      //   resellerPhone: this.props.user.phoneNumber,
      //   accessCode: this.props.user.accessCode,
      // });
    }
  }

  handleSelectChange = (event) => {
    this.setState({ dummyPhoneNumber: event.target.value });
  };

  handleBrandSelect = (event) => {
    this.setState({ activeBrandIndex: event.target.value.toString()});
  };

  onInputChange = (e) => {
    this.setState({ redeemValue: e.target.value });
  };

  onSelectValue = (e, c) => {
    this.setState({
      redeemValue: e.target.getAttribute("data-value"),
      activeCustomDenominations: c,
    });
  };

  displayCard() {
    if (this.state.activeBrandIndex == 0) {
      return _.find(this.props.cards.cards, (c) => {
        return c.productId.toString() == "9142" && c.parent == true;
      });
    } else if (this.state.activeBrandIndex == 1) {
      return _.find(this.props.cards.cards, (c) => {
        return c.productId.toString() == "9146" && c.parent == true;
      });
    } else if (this.state.activeBrandIndex == 2) {
      return _.find(this.props.cards.cards, (c) => {
        return c.productId.toString() == "9260" && c.parent == true;
      });
    } else if ([2,3,4,5,6,7,8,9, 10, 11, 12].includes(parseInt(this.state.activeBrandIndex))) {
      return _.find(this.props.cards.cards, (c) => {
        return c.productId.toString() == "9142" && c.parent == true;
      });
    } else {
      return <div></div>
    }
  }

  getVoucher = async () => {
    let paymentDetails;
    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];
    let displayCard = this.displayCard();

    let { user } = this.props;

    if (
      this.state.redeemValue < activeBrand.minValue ||
      this.state.redeemValue > activeBrand.maxValue
    ) {
      this.setState({
        btnText: "Redeem Now",
        alert: true,
        alertMessage: `Please enter amount between ${activeBrand.minValue} to ${activeBrand.maxValue}`,
        disabled: !this.state.disabled,
      });

      setTimeout(() => {
        this.setState({
          alert: false,
          alertMessage: "",
          disabled: !this.state.disabled,
        });
      }, 4000);

      setTimeout(() => {}, 5000);

      return;
    } else {
      this.setState({
        disabled: !this.state.disabled,
        btnText: "Deducting from Card ...",
        loading: true,
      });

      // Try for Paymetn Id
      let paymentId;
      try {
        paymentId = await this.props.paymentActions.fetchPaymentId({
          accessCode: this.props.user.accessCode,
        });
      } catch (e) {
        console.log(e);
      }

      paymentDetails = await this.props.paymentActions.redeemFromCard({
        paymentId: paymentId.paymentId, // Get Payment Id before
        totalAmount: this.state.redeemValue,
        walletPaymentAmount: 0,
        cardId: displayCard.cardId,
        cardPaymentAmount: this.state.redeemValue,
        transactionId: null,
        razorpayPaymentAmount: 0,
        productId: activeBrand.id,
        accessCode: this.props.user.accessCode,
      });
    }

    if (Object.keys(paymentDetails).length === 0) {
      this.setState({
        btnText: "Redeem Now",
        alert: true,
        alertMessage: "Unable to pay with card",
        loading: false,
      });
      setTimeout(() => {
        this.setState({
          alert: false,
          alertMessage: "",
          disabled: !this.state.disabled,
        });
      }, 4000);

      return;
    } else {
      this.setState({
        btnText: "Generating Voucher ...",
        loading: true,
        switchDisabled: false,
      });

      if (paymentDetails.amount == 0) {
        this.setState({
          btnText: "Redeem Now",
          alert: true,
          alertMessage: "Please enter amount",
          loading: false,
        });
        setTimeout(() => {
          this.setState({ alert: false, alertMessage: "", loading: false });
        }, 4000);
      }
      

      let order_details = await this.props.orderActions.getVoucher({
        amount: this.state.redeemValue,
        ref_no: paymentDetails.paymentId,
        productId: activeBrand.id, // Amazon only
        sku: activeBrand.sku,
        parent: false,
        parentCardId: displayCard.cardId,
        phonenumber: this.props.user.phoneNumber,
        accessCode: this.props.user.accessCode,
        paymentId: paymentDetails.paymentId,
        paymentSignature: paymentDetails.paymentSignature,
      });

      if (order_details.status === 500) {
        this.setState({
          loading: false,
          btnText: "Redeem Now",
          alert: true,
          // alertMessage: "Unable to generate voucher",
          alertMessage: order_details.data.errorMessage,
        });

        setTimeout(() => {
          this.setState({
            alert: false,
            alertMessage: "",
            disabled: !this.state.disabled,
          });
        }, 20000);

        return;
      }

      await this.props.actions.getCards({
        access_token: this.props.user.accessCode,
        number: this.props.user.phoneNumber,
        pageNumber: 1,
      });

      this.setState({
        loading: false,
        voucher: order_details,
        disabled: !this.state.disabled,
        btnText: "Redeem Now",
      });

      // setTimeout(() => {
      //   this.setState({ voucher: null, switchDisabled: false });
      // }, 15000);
    }
  };

  logout = async () => {
    this.props.loginActions.logout().then(() => {
      this.props.history.push("/login");
    });
  };

  render() {
    let displayCard = this.displayCard();
    const { loading } = this.props.cards;
    const activeBrand = this.state.validBrands[this.state.activeBrandIndex];
    // console.log("order_details", activeBrand, displayCard);
    if (loading) {
      return (
        <>
          <Container>
            <Row>
              <Col
                sm={{ span: 6, offset: 3 }}
                style={{
                  marginTop: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner animation="grow" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>
            </Row>
          </Container>
        </>
      );
    }

    return (
      <>
         {displayCard ? (
          <Container>
            <Row>
              <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 30 }}>
                {this.state.alert && (
                  <Alert variant={"danger"}>{this.state.alertMessage} </Alert>
                )}
                <div className="card-details">
                  <p className="card-balance">
                    Registered Number: {this.props.user.phoneNumber}{" "}
                  </p>
                  <p className="card-balance">
                    Card Number: {displayCard.cardId}{" "}
                  </p>
                  <p className="card-balance">
                    Balance: &#8377;{" "}
                    {displayCard.card_balance &&
                      parseFloat(displayCard.card_balance).toFixed(2)}{" "}
                  </p>
                </div>   
                {/* <Form.Group>
                <Form.Label>Select Number</Form.Label>
                 <Form.Control as="select" onChange={this.handleSelectChange}>
                    <option value="">Select Phonenumber to use </option>
                    {this.props.phoneNumbers.phone_numbers.map(phone => {
                      return (
                        <option key={phone.id} value={phone.phone_number}>
                          {phone.phone_number}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>*/}
                <Form.Group>
                  <Form.Label>Select Brand</Form.Label>
                  <div className="inputSelect">
                    {this.state.validBrands.map((brand, index) => (
                      <Form.Check
                        // className={activeBrand.id === brand.id ? `active` : ""}
                        onClick={this.handleBrandSelect}
                        htmlFor={`${brand.name}`}
                        id={`${brand.name}`}
                        disabled={this.state.switchDisabled}
                        type={"radio"}
                        name="activeBrand"
                        label={`${brand.name}`}
                        value={`${index}`}
                        checked={activeBrand.id === brand.id}
                      />
                    ))}
                  </div> 
                </Form.Group>
                <Form.Group>
                  <Form.Label>Choose or Enter a Value to Redeem</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.redeemValue}
                    placeholder=""
                    onChange={this.onInputChange}
                  />
                </Form.Group> 
                <ul className="custom">
                  {activeBrand.custom_denominations.map((c) => {
                    return (
                      <li
                        data-value={c}
                        onClick={(e) => this.onSelectValue(e, c)}
                        className={
                          this.state.activeCustomDenominations === c
                            ? "active"
                            : ""
                        }
                        style={
                          this.state.activeCustomDenominations === c
                            ? { backgroundColor: "#007bff", color: "#ffffff" }
                            : {}
                        }
                      >
                        {c}
                      </li>
                    );
                  })}
                </ul>
                <Button
                  block
                  disabled={this.state.disabled}
                  variant="danger"
                  onClick={() => {
                    this.getVoucher();
                    this.setState({ switchDisabled: true });
                  }}
                >
                  {this.state.loading === true ? (
                    <span style={{ marginRight: "10px" }}>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </span>
                  ) : null}

                  {this.state.btnText}
                </Button> 
              </Col>
            </Row>
          </Container>
          ) : (
          <Container>
            <Row>
              <Col
                sm={{ span: 6, offset: 3 }}
                style={{ marginTop: 30, textAlign: "center" }}
              >
               <Alert variant={"info"}>No cards to display</Alert>
              </Col>
            </Row>
          </Container>
        )}   

      {/* Display when Voucher exists */}
        {this.state.voucher && [1544,328,325,329,327,326,324,338,336,789].includes(parseInt(activeBrand.id)) ? (
          <Container>
            <Row>
              <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 30 }}>
                <h4
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  Voucher code
                </h4>

                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Click to Copy <strong>Voucher ID</strong>.
                    </Tooltip>
                  }
                >
                  <CopyToClipboard
                    text={this.state.voucher.pin_or_url}
                    onCopy={() => {
                      this.setState({ voucherCopied: true });
                      setTimeout(() => {
                        this.setState({ voucherCopied: false });
                      }, 1500);
                    }}
                  >
                    <p className="voucher-code">
                      <Image
                        className="amazon-image"
                        src="https://static.echojoy.in/logos/amazon.png"
                        rounded
                      />
                      {this.state.voucher.pin_or_url}
                    </p>
                  </CopyToClipboard>
                </OverlayTrigger>
                {this.state.voucherCopied ? (
                  <div className="feedback-homepage">
                    <span className="message-homepage">Copied!</span>
                  </div>
                ) : null}
                {/* <Button
                  target="_blank"
                  href={`https://www.amazon.in/gp/css/gc/payment?ie=UTF8&actionType=add&claimCode=${this.state.voucher.pin_or_url}`}
                  block
                  variant="danger"
                >
                  Add Amazon Voucher
                </Button> */}
              </Col>
            </Row>
          </Container>
        ) : null}
        {this.state.voucher && activeBrand.id == "6861" ? (
          <Container>
            <Row>
              <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 30 }}>
                <h4
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  Voucher code
                </h4>

                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Click to Copy <strong>Voucher ID</strong>.
                    </Tooltip>
                  }
                >
                  <CopyToClipboard
                    text={this.state.voucher.pin_or_url}
                    onCopy={() => {
                      this.setState({ voucherCopied: true });
                      setTimeout(() => {
                        this.setState({ voucherCopied: false });
                      }, 1500);
                    }}
                  >
                    <p className="voucher-code">
                      <Image
                        className="amazon-image"
                        src="https://static.echojoy.in/logos/amazonsv.jpg"
                        rounded
                      />
                      {this.state.voucher.pin_or_url}
                    </p>
                  </CopyToClipboard>
                </OverlayTrigger>
                {this.state.voucherCopied ? (
                  <div className="feedback-homepage">
                    <span className="message-homepage">Copied!</span>
                  </div>
                ) : null}
                {/* <Button
                  target="_blank"
                  href={`https://www.amazon.in/gp/css/gc/payment?ie=UTF8&actionType=add&claimCode=${this.state.voucher.pin_or_url}`}
                  block
                  variant="danger"
                >
                  Add Amazon Voucher
                </Button> */}
              </Col>
            </Row>
          </Container>
        ) : null}
        {this.state.voucher && activeBrand.id == "9259" ? (
          <Container>
            <Row>
              <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 30 }}>
                <h4
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                >
                  Voucher code
                </h4>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Click to Copy <strong>Voucher ID</strong>.
                    </Tooltip>
                  }
                >
                  <CopyToClipboard
                    text={this.state.voucher.cardnumber}
                    onCopy={() => {
                      this.setState({ voucherCopied: true });
                      setTimeout(() => {
                        this.setState({ voucherCopied: false });
                      }, 1500);
                    }}
                  >
                    <p className="voucher-code">
                      <Image
                        className="amazon-image"
                        src="https://static.echojoy.in/logos/flipkart.png"
                        rounded
                      />
                      {this.state.voucher.cardnumber}
                    </p>
                  </CopyToClipboard>
                </OverlayTrigger>
                {this.state.voucherCopied ? (
                  <div className="feedback-homepage">
                    <span className="message-homepage">Copied!</span>
                  </div>
                ) : null}
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Click to Copy <strong>Voucher PIN</strong>.
                    </Tooltip>
                  }
                >
                  <CopyToClipboard
                    text={this.state.voucher.pin_or_url}
                    onCopy={() => {
                      this.setState({ voucherCopied: true });
                      setTimeout(() => {
                        this.setState({ voucherCopied: false });
                      }, 1500);
                    }}
                  >
                    <p className="voucher-code">
                      {this.state.voucher.pin_or_url}
                    </p>
                  </CopyToClipboard>
                </OverlayTrigger>
                {this.state.voucherCopied ? (
                  <div className="feedback-homepage">
                    <span className="message-homepage">Copied!</span>
                  </div>
                ) : null}
              </Col>
            </Row>
          </Container>
        ) : null}
      </>
    );
  }
}

HomePage.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  paymentActions: PropTypes.object,
  loginActions: PropTypes.object,
  orderActions: PropTypes.object,
  cards: PropTypes.array,
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      phone_number: PropTypes.string,
      id: PropTypes.number,
    })
  ),
};

function mapStateToProps(state) {
  return {
    user: state.user,
    cards: state.cards,
    phoneNumbers: state.phoneNumbers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
    orderActions: bindActionCreators(orderActions, dispatch),
    phoneActions: bindActionCreators(phoneActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
