import * as actions from "../actions/loginActions";
import objectAssign from "object-assign";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable.
const initialState = {
  otpStatus: actions.NOT_REQUESTED_OTP,
  phoneNumber: null,
  accessCode: null,
  loading: false,
  isAuthenticated: false
};

export default function userReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case actions.REQUEST_OTP:
      newState = objectAssign({}, state);
      return {
        ...newState,
        phoneNumber: action.phoneNumber,
        otpStatus: action.type,
        loading: true
      };
    case actions.REQUEST_OTP_FAILED:
      return { ...state, otpStatus: action.type, loading: false };
    case actions.REQUEST_OTP_SUCCESS:
      return { ...state, otpStatus: action.type, loading: false };
    case actions.VERIFY_OTP_REQUEST:
      return {
        ...state,
        otpStatus: action.type,
        loading: true
      };
    case actions.VERIFY_OTP_FAILED:
      return { ...state, otpStatus: action.type, loading: false };
    case actions.VERIFY_OTP_SUCCESS:
      newState = objectAssign({}, state);
      return {
        ...newState,
        accessCode: action.result.data.access_token,
        otpStatus: action.type,
        loading: false,
        isAuthenticated: true
      };
    case actions.NOT_REQUESTED_OTP:
      return {
        ...state,
        otpStatus: action.type,
        loading: false,
        isAuthenticated: false
      };
    case actions.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
