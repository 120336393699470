/* eslint require-atomic-updates: off */

import {
  pay,
  getRefId,
  makeSelfPaymentAPI,
  getPaymentLinksAPI,
  addPaymentLinkAPI,
} from "../apis/payments";

export const REQUEST_PAYMENT = "REQUEST_PAYMENT";
export const REQUEST_PAYMENT_FAILED = "REQUEST_PAYMENT_FAILED";
export const REQUEST_PAYMENT_SUCCESS = "REQUEST_PAYMENT_SUCCESS";
export const PAYMENT_ID_REQUEST_FAILED = "PAYMENT_ID_REQUEST_FAILED";

export const REQUEST_SELF_PAYMENT = "REQUEST_SELF_PAYMENT";
export const REQUEST_SELF_PAYMENT_FAILED = "REQUEST_SELF_PAYMENT_FAILED";
export const REQUEST_SELF_PAYMENT_SUCCESS = "REQUEST_SELF_PAYMENT_SUCCESS";

export const CLOSE_ALERT = "CLOSE_ALERT";

export const REQUEST_PAYMENT_LINKS = "REQUEST_PAYMENT_LINKS";
export const REQUEST_PAYMENT_LINKS_SUCCESS = "REQUEST_PAYMENT_LINKS_SUCCESS";
export const REQUEST_PAYMENT_LINKS_FAILED = "REQUEST_PAYMENT_LINKS_FAILED";
export const RESET_ADD_MONEY_FORM = "RESET_ADD_MONEY_FORM";

export const REQUEST_ADD_PAYMENTLINK = "REQUEST_ADD_PAYMENTLINK";
export const REQUEST_ADD_PAYMENTLINK_FAILED = "REQUEST_ADD_PAYMENTLINK_FAILED";
export const REQUEST_ADD_PAYMENTLINK_SUCCESS =
  "REQUEST_ADD_PAYMENTLINK_SUCCESS";

export const redeemFromCard = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_PAYMENT });

  return pay(data)
    .then((result) => {
      dispatch({ type: REQUEST_PAYMENT_SUCCESS });
      return result;
    })
    .catch((error) => {
      dispatch({ type: REQUEST_PAYMENT_FAILED });
      return error;
    });
};

export const fetchPaymentId = (data) => async (dispatch) => {
  return getRefId(data)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      dispatch({ type: PAYMENT_ID_REQUEST_FAILED });
      return error;
    });
};

export const makeSelfPayment = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_SELF_PAYMENT });
  return makeSelfPaymentAPI(data)
    .then((result) => {
      dispatch({ type: REQUEST_SELF_PAYMENT_SUCCESS, data: data });
      return result;
    })
    .catch((error) => {
      dispatch({ type: REQUEST_SELF_PAYMENT_FAILED });
      return error;
    });
};

export const resetAddMoneyForm = () => {
  return {
    type: RESET_ADD_MONEY_FORM,
  };
};

export const closeAlert = () => {
  return {
    type: CLOSE_ALERT,
  };
};

// payment links
export const getPaymentLinks = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_PAYMENT_LINKS });
  return getPaymentLinksAPI(data)
    .then((result) => {
      dispatch({
        type: REQUEST_PAYMENT_LINKS_SUCCESS,
        items: result.data.data.items,
        lastEvaluatedKey: result.data.data.lastEvaluatedKey,
      });
    })
    .catch((e) => {
      if (e.response.status === 500) {
        dispatch({
          type: REQUEST_PAYMENT_LINKS_FAILED,
          errorMsg: "Something went wrong",
        });
      } else {
        dispatch({
          type: REQUEST_PAYMENT_LINKS_FAILED,
          errorMsg: "Something went wrong",
        });
      }
    });
};

// add payment link
export const addPaymentLink = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_ADD_PAYMENTLINK });

  return addPaymentLinkAPI(data)
    .then((result) => {
      dispatch({
        type: REQUEST_ADD_PAYMENTLINK_SUCCESS,
        paymentLinkObj: result.data.data,
      });
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: REQUEST_ADD_PAYMENTLINK_FAILED,
          errorMsg: "Something went wrong",
        });
      } else {
        dispatch({
          type: REQUEST_ADD_PAYMENTLINK_FAILED,
          errorMsg: "Something went wrong",
        });
      }
    });
};
