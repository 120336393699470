import { getUserCards } from "../apis/cards";

export const REQUEST_CARDS = "REQUEST_CARDS";
export const REQUEST_CARDS_FAILED = "REQUEST_CARDS_FAILED";
export const REQUEST_CARDS_SUCCESS = "REQUEST_CARDS_SUCCESS";

// example of a thunk using the redux-thunk middleware
// export const getCards = data => async dispatch => {
//   dispatch({ type: REQUEST_CARDS });
//   return getUserCards(data)
//     .then(result => {
//       if (result.length > 0) {
//         dispatch({ type: REQUEST_CARDS_SUCCESS, cards: result });
//       } else {
//         // dispatch({ type: REQUEST_CARDS_FAILED })
//       }
//     })
//     .catch(() => {});
// };

export const getCards = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_CARDS });
  return getUserCards(data)
    .then((result) => {
      const cards = _.orderBy(result.data.data, "updatedAt");
      // cards.reverse();
      dispatch({
        type: REQUEST_CARDS_SUCCESS,
        cards: cards,
        totalCount: result.data.totalCount,
        page: result.data.page,
        size: result.data.size,
      });
      if (result.data.data.length === 0) {
        dispatch({ type: REQUEST_CARDS_FAILED });
        dispatch({
          type: ADD_FLASH_MESSAGE,
          variant: "success",
          flashMsg: "There are no cards for this number",
        });

        setTimeout(() => {
          dispatch({ type: DELETE_FLASH_MESSAGE });
        }, 3000);
      }
    })
    .catch((error) => {
      dispatch({ type: REQUEST_CARDS_FAILED });
      dispatch({
        type: ADD_FLASH_MESSAGE,
        variant: "danger",
        flashMsg: "Something went wrong",
      });

      setTimeout(() => {
        dispatch({ type: DELETE_FLASH_MESSAGE });
      }, 3000);
    });
};
