/* eslint require-atomic-updates: off */
import axios from "axios";
import { getHostUrl } from "../utils/env";

const HOST_URL = getHostUrl();

export async function getRefId(data) {
  return new Promise((res, rej) =>
    axios
      .get(`${HOST_URL}/payments/get-reference-id/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${data.accessCode}`,
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        // alert(JSON.stringify(error))
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function pay(formData) {
  const formDataObj = {
    paymentId: formData.paymentId,
    totalAmount: formData.totalAmount,
    walletPaymentAmount: formData.walletPaymentAmount,
    cardId: formData.cardId,
    cardPaymentAmount: formData.cardPaymentAmount,
    transactionId: formData.transactionId,
    razorpayPaymentAmount: formData.razorpayPaymentAmount,
    productId: formData.productId,
  };
  return new Promise((res, rej) =>
    axios
      .post(`${HOST_URL}/payments/pay/`, formDataObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${formData.accessCode}`,
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        // alert(JSON.stringify(error))
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

// makeSelfPaymentAPI;

export function makeSelfPaymentAPI(data) {
  const dataObj = {
    paymentId: data.paymentId,
    transactionId: data.transactionId,
    cardId: data.cardId,
    transactionType: data.transactionType,
    approvalStatus: data.approvalStatus,
    productId: data.productId,
    razorpayPaymentAmount: data.razorpayPaymentAmount,
    totalAmount: data.totalAmount,
  };
  return new Promise((res, rej) =>
    axios
      .post(`${HOST_URL}/payments/pay`, dataObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${data.accessCode}`,
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        // alert(JSON.stringify(error))
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

// payment links api

export function getPaymentLinksAPI(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/payment/link`,
        {
          lastEvaluatedKey: data.lastEvaluatedKey,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        // alert(JSON.stringify(error))
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(error);
      })
  );
}

//add payment link api
export function addPaymentLinkAPI(data) {
  const dataObj = {
    amount: parseInt(data.amount),
    cardId: data.cardId,
    productId: data.productId
  };
  return new Promise((res, rej) =>
    axios
      .post(`${HOST_URL}/payment/link/create`, dataObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${data.access_token}`,
        },
      })
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(error);
      })
  );
}
