import * as actionTypes from "../actions/resellerActivityActions";
import * as actionType from "../actions/loginActions";

const initialState = {
  loading: false,
  reseller_activity: [],
  error: null,
  totalCount: null,
  page: null,
  size: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // phonenumber list
    case actionTypes.REQUEST_RESELLER_ACTIVITY:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REQUEST_RESELLER_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        reseller_activity: action.resellerActivity,
        totalCount: action.totalCount,
        page: action.page,
        size: action.size,
      };

    case actionTypes.REQUEST_RESELLER_ACTIVITY_FAILED:
      return {
        ...state,
        loading: false,
        reseller_activity: [],
      };
    case actionType.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
