/* eslint-disable import/no-named-as-default */
// Reseller APP
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { hot } from "react-hot-loader";
import _ from "lodash";

import HomePage from "./containers/HomePage";
import TransactionsPage from "./containers/TransactionsPage";
import LoginPage from "./containers/LoginPage";
import RegisterPage from "./containers/Register";
import LogoutPage from "./containers/LogoutPage";
import PhoneCrudPage from "./containers/PhoneCrudPage";
import PaymentsPage from "./containers/PaymentsPage";
import NotFoundPage from "./NotFoundPage";
import BulkCoupons from "./containers/BulkCoupons";
import AddMoney from "./containers/AddMoney";
import Reports from "./containers/Reports/Reports";
import Refunds from "./containers/Refunds";
import PaymentLinks from "./containers/PaymentLinks/PaymentLinks";
import PaymentLinksPage from "./containers/PaymentLinks/PaymentLinksPage";
import EjNavbar from "./EjNavbar";

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

const PrivateRoute = ({ component: Component, auth, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) =>
        auth === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const PublicRoute = ({ component: Component, auth, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) =>
        auth === false ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

class App extends React.Component {
  render() {
    let auth = false;
    if (this.props.user) {
      auth = this.props.user.isAuthenticated;
    }

    return (
      <>
        <EjNavbar />
        <Switch>
          <PrivateRoute exact path="/" auth={auth} component={HomePage} />
          <PublicRoute auth={auth} path="/register" component={RegisterPage} />
          <PublicRoute auth={auth} path="/login" component={LoginPage} />
          <PrivateRoute
            auth={auth}
            path="/transactions"
            component={TransactionsPage}
          />
          <PrivateRoute
            auth={auth}
            path="/phonelist"
            component={PhoneCrudPage}
          />
          <PrivateRoute auth={auth} path="/payments" component={PaymentsPage} />
          <PrivateRoute
            auth={auth}
            path="/bulkcoupons"
            component={BulkCoupons}
          />
          <PrivateRoute auth={auth} path="/reports" component={Reports} />
          <PrivateRoute
            auth={auth}
            path="/paymentlinks"
            component={PaymentLinksPage}
            {...this.props}
          />
          <PrivateRoute auth={auth} path="/add-money" component={AddMoney} />
          <PrivateRoute auth={auth} path="/refunds" component={Refunds} />
          <Route component={NoMatch} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default hot(module)(connect(mapStateToProps, null)(App));
