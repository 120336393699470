import * as actionTypes from "../actions/resellerActivityActions";
import * as actionType from "../actions/loginActions";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  loading: false,
  debits: [],
  error: false,
  lastEvaluatedKey: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // phonenumber list
    case LOCATION_CHANGE:
      if (action.payload.location.pathname !== "/transactions") {
        return { ...initialState };
      } else {
        return {
          ...state,
        };
      }

    case actionTypes.REQUEST_RESELLER_DEBITS_SCROLL:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case actionTypes.REQUEST_RESELLER_DEBITS_SUCCESS_SCROLL:
      const activeBrand = action.activeBrand;

      // console.log(state.debits[0].productId);

      return {
        ...state,
        loading: false,
        debits: [...state.debits, ...action.Items],
        lastEvaluatedKey: action.lastEvaluatedKey,
        errorMsg: "",
        error: false,
      };

    //   if (state.debits.length === 0) {
    //     return {
    //       ...state,
    //       loading: false,
    //       debits: action.Items,
    //       lastEvaluatedKey: action.lastEvaluatedKey,
    //       errorMsg: "",
    //       error: false,
    //     };
    //   } else if (activeBrand === state.debits[0].productId) {
    //     return {
    //       ...state,
    //       loading: false,
    //       debits: [...state.debits, ...action.Items],
    //       lastEvaluatedKey: action.lastEvaluatedKey,
    //       errorMsg: "",
    //       error: false,
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       loading: false,
    //       debits: action.Items,
    //       lastEvaluatedKey: action.lastEvaluatedKey,
    //       errorMsg: "",
    //       error: false,
    //     };
    //   }

    case actionTypes.REQUEST_RESELLER_DEBITS_FAILED_SCROLL:
      return {
        ...state,
        loading: false,
        error: true,
        //errorMsg: action.errorMsg,
      };

    case actionTypes.DEBIT_HISTORY_CARD_SWITCH_TAB: {
      return {
        ...state,
        loading: true,
        error: false,
        debits: [],
      };
    }

    case actionType.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}

// const initialState = {
//   loading: false,
//   reseller_debits: [],
//   error: null,
//   totalCount: null,
//   page: null,
//   size: null
// };
// export default function(state = initialState, action) {
//   switch (action.type) {
//     // phonenumber list
//     case actionTypes.REQUEST_RESELLER_DEBITS:
//       return {
//         ...state,
//         loading: true
//       };
//     case actionTypes.REQUEST_RESELLER_DEBITS_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         reseller_debits: action.resellerDebits,
//         totalCount: action.totalCount,
//         page: action.page,
//         size: action.size
//       };
//     case actionTypes.REQUEST_RESELLER_DEBITS_FAILED:
//       return {
//         ...state,
//         loading: false,
//         reseller_debits: []
//       };
//     case actionType.LOGOUT:
//       return { ...initialState };
//     default:
//       return state;
//   }
// }
