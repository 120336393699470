import { placeStandardOrder } from "../apis/orders";

export const REQUEST_ORDER = "REQUREQUEST_ORDEREST_PAYMENT";
export const REQUEST_ORDER_FAILED = "REQUEST_ORDER_FAILED";
export const REQUEST_ORDER_SUCCESS = "REQUEST_ORDER_SUCCESS";

// example of a thunk using the redux-thunk middleware
export const getVoucher = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_ORDER });

  // return
  return placeStandardOrder(data)
    .then((result) => {
      dispatch({ type: REQUEST_ORDER_SUCCESS });
      return result;
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: REQUEST_ORDER_FAILED });
      return error;
    });
};
