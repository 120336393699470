import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/loginActions";

class EjNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginScreen: props.location.pathname === "/login",
      isRegisterScreen: props.location.pathname === "/register",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        isLoginScreen: this.props.location.pathname === "/login",
        isRegisterScreen: this.props.location.pathname === "/register",
      });
    }
  }

  logout = () => {
    this.props.logout();
  };

  render() {
    const { isLoginScreen, isRegisterScreen } = this.state;
    const { auth, user } = this.props;

    return (
      <Navbar className="ej-nav">
        <Navbar.Brand>
          <Link to="/">Echojoy | v1.0.1</Link>
        </Navbar.Brand>
        {!auth ? (
          <Nav className="mr-auto">
            {isLoginScreen ? (
              <Link to="/register">Register</Link>
            ) : isRegisterScreen ? (
              <Link to="/login">Login</Link>
            ) : null}
          </Nav>
        ) : (
          <Nav className="mr-auto">
            <Link to="/">Logged In As: {user.phoneNumber}</Link>
            <NavLink
              exact
              className="navbarlink"
              activeClassName="is-active"
              to="/"
            >
              Get Voucher
            </NavLink>
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/transactions"
            >
              Debit History
            </NavLink>
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/payments"
            >
              Credit History
            </NavLink>
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/reports"
            >
              Reports
            </NavLink>
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/paymentlinks"
            >
              Payment Links
            </NavLink>
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/add-money"
            >
              Add Money
            </NavLink>
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/refunds"
            >
              Refunds
            </NavLink>
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/login"
              onClick={this.logout}
            >
              Logout
            </NavLink>
          </Nav>
        )}
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.user.isAuthenticated,
    user: state.user,
  };
};

export default withRouter(connect(mapStateToProps, { logout })(EjNavbar));