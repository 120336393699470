import axios from "axios";
import * as _ from "lodash";
import { getHostUrl } from '../utils/env';

const HOST_URL = getHostUrl();

export function getUserCards(data) {
  return new Promise((res, rej) =>
    axios
      .get(
        `${HOST_URL}/resellers/cards/${data.number}?page=${data.pageNumber}&size=10`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`
          }
        }
      )
      .then(response => {
        // const cards = _.orderBy(response.data.data, "updatedAt");
        // cards.reverse();

        res(response);
      })
      .catch(error => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new(error))
      })
  );
}
