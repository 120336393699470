import { getCardsByTimeIntervalProductIdAndNumber, getCreditsReportByPhoneNumber } from "../apis/reports";

export const REQUEST_CARD_LIST_REPORT = "REQUEST_CARD_LIST_REPORT";
export const REQUEST_CARD_LIST_REPORT_FAILED =
  "REQUEST_CARD_LIST_REPORT_FAILED";
export const REQUEST_CARD_LIST_REPORT_SUCCESS =
  "REQUEST_CARD_LIST_REPORT_SUCCESS";

export const REMOVE_REQUEST_CARD_LIST_REPORT_ERROR_MSG =
  "REMOVE_REQUEST_CARD_LIST_REPORT_ERROR_MSG";

// example of a thunk using the redux-thunk middleware
export const getCardsListReport = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_CARD_LIST_REPORT });
  return getCardsByTimeIntervalProductIdAndNumber(data)
    .then((result) => {
      // cards.reverse();
      dispatch({
        type: REQUEST_CARD_LIST_REPORT_SUCCESS,
        reports: null,
      });

      return result;
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: REQUEST_CARD_LIST_REPORT_FAILED,
        });
      } else {
        dispatch({
          type: REQUEST_CARD_LIST_REPORT_FAILED,
        });
      }

      setTimeout(() => {
        dispatch({
          type: REMOVE_REQUEST_CARD_LIST_REPORT_ERROR_MSG,
        });
      }, 4000);

      return error;
    });
};

// example of a thunk using the redux-thunk middleware
export const getCreditsReport = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_CARD_LIST_REPORT });
  return getCreditsReportByPhoneNumber(data)
    .then((result) => {
      // cards.reverse();
      dispatch({
        type: REQUEST_CARD_LIST_REPORT_SUCCESS,
        reports: null,
      });

      return result;
    })
    .catch((error) => {
      if (error.response.status === 500) {
        dispatch({
          type: REQUEST_CARD_LIST_REPORT_FAILED,
        });
      } else {
        dispatch({
          type: REQUEST_CARD_LIST_REPORT_FAILED,
        });
      }

      setTimeout(() => {
        dispatch({
          type: REMOVE_REQUEST_CARD_LIST_REPORT_ERROR_MSG,
        });
      }, 4000);

      return error;
    });
};
