import { getRefunds as fetchRefunds } from "../apis/refunds";

export const REQUEST_REFUNDS = "REQUEST_REFUNDS";
export const REQUEST_REFUNDS_FAILED = "REQUEST_REFUNDS_FAILED";
export const REQUEST_REFUNDS_SUCCESS = "REQUEST_REFUNDS_SUCCESS";

export const getRefunds = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_REFUNDS });
  return fetchRefunds(data)
    .then((result) => {
      const {
        data: {
          data: { items },
        },
      } = result;

      dispatch({
        type: REQUEST_REFUNDS_SUCCESS,
        payload: [...items],
      });
    })
    .catch((error) => {
      dispatch({ type: REQUEST_REFUNDS_FAILED, payload: { error } });
    });
};
