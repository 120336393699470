import {
  getResellerActivityAPI,
  getResellerDebitsAPI,
  getResellerDebitsInfiniteScrollAPI,
} from "../apis/resellerActivity";

export const REQUEST_RESELLER_ACTIVITY = "REQUEST_RESELLER_ACTIVITY";
export const REQUEST_RESELLER_ACTIVITY_FAILED =
  "REQUEST_RESELLER_ACTIVITY_FAILED";
export const REQUEST_RESELLER_ACTIVITY_SUCCESS =
  "REQUEST_RESELLER_ACTIVITY_SUCCESS";

export const REQUEST_RESELLER_DEBITS = "REQUEST_RESELLER_DEBITS";
export const REQUEST_RESELLER_DEBITS_FAILED = "REQUEST_RESELLER_DEBITS_FAILED";
export const REQUEST_RESELLER_DEBITS_SUCCESS =
  "REQUEST_RESELLER_DEBITS_SUCCESS";

export const REQUEST_RESELLER_DEBITS_SCROLL = "REQUEST_RESELLER_DEBITS_SCROLL";
export const REQUEST_RESELLER_DEBITS_FAILED_SCROLL =
  "REQUEST_RESELLER_DEBITS_FAILED_SCROLL";
export const REQUEST_RESELLER_DEBITS_SUCCESS_SCROLL =
  "REQUEST_RESELLER_DEBITS_SUCCESS_SCROLL";

export const DEBIT_HISTORY_CARD_SWITCH_TAB = "DEBIT_HISTORY_CARD_SWITCH_TAB";

// example of a thunk using the redux-thunk middleware
export const getResellerActivity = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_RESELLER_ACTIVITY });
  return getResellerActivityAPI(data)
    .then((result) => {
      dispatch({
        type: REQUEST_RESELLER_ACTIVITY_SUCCESS,
        resellerActivity: result.data.data,
        totalCount: result.data.totalCount,
        page: result.data.page,
        size: result.data.size,
      });
    })
    .catch(() => {});
};

export const getResellerDebits = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_RESELLER_DEBITS });
  return getResellerDebitsAPI(data)
    .then((result) => {
      dispatch({
        type: REQUEST_RESELLER_DEBITS_SUCCESS,
        resellerDebits: result.data.data,
        totalCount: result.data.totalCount,
        page: result.data.page,
        size: result.data.size,
      });
    })
    .catch(() => {});
};

// new reseller debits scroling
export const getResellerDebitsInfiniteScroll = (data) => async (dispatch) => {
  // console.log(data,"data");
  dispatch({ type: REQUEST_RESELLER_DEBITS_SCROLL });
  return getResellerDebitsInfiniteScrollAPI(data)
    .then((result) => {
      // console.log(result,"result");
      dispatch({
        type: REQUEST_RESELLER_DEBITS_SUCCESS_SCROLL,
        Items: result.data.data,
        lastEvaluatedKey: result.data.lastEvaluatedKey,
        activeBrand: data.productId,
      });
    })
    .catch((e) => {
      if (
        e.response.status === 500 &&
        e.response.data.error === "INVALID_PHONE_NUMBER"
      ) {
        dispatch({
          type: REQUEST_RESELLER_DEBITS_FAILED_SCROLL,
          errorMsg: "Invalid PhoneNumber",
        });
      } else if (e.response.status === 500) {
        dispatch({
          type: REQUEST_RESELLER_DEBITS_FAILED_SCROLL,
          errorMsg: "Something went wrong",
        });
      }
    });
};

export const debitHistorySwitchTab = () => {
  return {
    type: DEBIT_HISTORY_CARD_SWITCH_TAB,
  };
};
