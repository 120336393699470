// import * as actions from "../actions/cardActions";
// import objectAssign from "object-assign";
// import * as actionType from "../actions/loginActions";

// const initialState = {
//   cards: [],
//   loading: false,
//   error: null,
//   loading: false
// };

// export default function cardsReducer(state = initialState.cards, action) {
//   let newState;
//   switch (action.type) {
//     case actions.REQUEST_CARDS:
//       return { ...state, loading: true };
//     case actions.REQUEST_CARDS_FAILED:
//       return { ...state, loading: false };
//     case actions.REQUEST_CARDS_SUCCESS:
//       newState = objectAssign({}, state);
//       return { ...newState, cards: action.cards, loading: false };
//     case actionType.LOGOUT:
//       return { ...initialState };
//     default:
//       return state;
//   }
// }

import * as actions from "../actions/cardActions";
import objectAssign from "object-assign";
import * as actionType from "../actions/loginActions";

const initialState = {
  cards: [],
  loading: false,
  error: null,
  loading: false,
  totalCount: null,
  page: null,
  size: null
};

export default function cardsReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case actions.REQUEST_CARDS:
      return { ...state, loading: true };
    case actions.REQUEST_CARDS_FAILED:
      return { ...state, loading: false };
    case actions.REQUEST_CARDS_SUCCESS:
      //   newState = objectAssign({}, state);
      return {
        ...state,
        cards: action.cards,
        loading: false,
        totalCount: action.totalCount,
        page: action.page,
        size: action.size
      };
    case actionType.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
