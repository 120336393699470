import * as actionTypes from "../actions/paymentActions";
import * as actionType from "../actions/loginActions";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  loading: false,
  paymentLinks: [],
  error: false,
  lastEvaluatedKey: null,
  paymentLink: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (action.payload.location.pathname !== "/paymentlinks") {
        return { ...initialState };
      } else {
        return {
          ...state,
        };
      }

    case actionTypes.REQUEST_PAYMENT_LINKS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case actionTypes.REQUEST_PAYMENT_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentLinks: [...state.paymentLinks, ...action.items],
        lastEvaluatedKey: action.lastEvaluatedKey,
        errorMsg: "",
        error: false,
      };

    case actionTypes.REQUEST_PAYMENT_LINKS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        //errorMsg: action.errorMsg,
      };

    case actionTypes.REQUEST_ADD_PAYMENTLINK:
      return {
        ...state,
        loading: true,
        error: false,
        error: false,
        //errorMsg: action.errorMsg,
      };

    case actionTypes.REQUEST_ADD_PAYMENTLINK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        paymentLink: action.paymentLinkObj,
        error: false,
      };

    case actionTypes.REQUEST_ADD_PAYMENTLINK_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        paymentLink: {},
      };

    case actionType.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
