import React from "react";

import { connect } from "react-redux";
import { Container, Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import CardsIssuedList from "./CardsIssuedList";
import CreditsList from "./CreditsList";

class Reports extends React.Component {
  render() {
    return (
      <>
        <Container>
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h5 className="element-header">Reports</h5>
              <Tabs defaultActiveKey="report-1" id="uncontrolled-tab-example">
                <Tab eventKey="report-1" title="Debits/Vouchers">
                  <br />
                  <CardsIssuedList />
                </Tab>
                <Tab eventKey="report-2" title="Credits">
                  <br />
                  <CreditsList />
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Reports);
